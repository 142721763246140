// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import DashNavbar from "./DashNavbar";
// import Sidebar from "./Sidebar";
// import Modal from "react-bootstrap/Modal";
// import "bootstrap/dist/css/bootstrap.min.css";
// import Button from "react-bootstrap/Button";
// import { Table, Pagination } from "react-bootstrap";
// import Swal from "sweetalert2";
// import { FcApproval, FcHighPriority } from "react-icons/fc";
// import Select from "react-select";

// const OfferLetterGenerator = () => {
//   const [letters, setLetters] = useState([]);
//   const [candidateNames, setCandidateNames] = useState([]);
//   const navigate = useNavigate();
//   const [searchQuery, setSearchQuery] = useState("");
//   const [filteredLetters, setFilteredLetters] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const itemsPerPage = 5;
//   const [isLoading, setIsLoading] = useState(false);

//   const [sentStatus, setSentStatus] = useState({});
//   const [isDownloading, setIsDownloading] = useState({}); // State for download loaders
//   const [isSending, setIsSending] = useState({}); // State for send loaders

//   useEffect(() => {
//     async function fetchLetters() {
//       try {
//         const response = await axios.get("https://tmaasbackend.tdtlworld.com/get_letters/");
//         const data = response.data;
//         if (data && Array.isArray(data.letters)) {
//           setLetters(data.letters);
//           initializeSentStatus(data.letters);
//         } else {
//           console.error("Invalid data format: letters field is not an array");
//           setLetters([]);
//         }
//       } catch (error) {
//         console.error("Error fetching letters:", error);
//         setLetters([]);
//       }
//     }
//     async function fetchCandidateNames() {
//       try {
//         const response = await axios.get(
//           "https://tmaasbackend.tdtlworld.com/get_candidate_names/"
//         );
//         const data = response.data;
//         if (data && Array.isArray(data.candidate_names)) {
//           setCandidateNames(data.candidate_names);
//         } else {
//           console.error(
//             "Invalid data format: candidate_names field is not an array"
//           );
//           setCandidateNames([]);
//         }
//       } catch (error) {
//         console.error("Error fetching candidate names:", error);
//         setCandidateNames([]);
//       }
//     }
//     fetchLetters();
//     fetchCandidateNames();
//   }, []);

//   const initializeSentStatus = (lettersData) => {
//     const initialSentStatus = {};
//     lettersData.forEach((letter) => {
//       initialSentStatus[letter.pk] = letter.fields.sent == 1;
//       console.log(letter.fields.sent);
//     });
//     setSentStatus(initialSentStatus);
//     console.log(initialSentStatus);
//   };
//   const sendEmail = async (candidateName, pdfPath, LetterType, index) => {
//     const updatedSending = { ...isSending };
//     updatedSending[index] = true;
//     setIsSending(updatedSending);

//     try {
//       const response = await axios.post(
//         "https://tmaasbackend.tdtlworld.com/send_offer_letter_email/",
//         {
//           candidate_name: candidateName,
//           pdf_path: pdfPath,
//           letter_type: LetterType,
//         }
//       );
//       Swal.fire({
//         icon: "success",
//         title: "Email Sent!",
//         text: "The email has been successfully sent.",
//         timer: 1000,
//         timerProgressBar: true,
//         showConfirmButton: false,
//       });
//       const updatedSentStatus = { ...sentStatus };
//       updatedSentStatus[letters[index].pk] = true;
//       setSentStatus(updatedSentStatus);

//       await axios.put(
//         `https://tmaasbackend.tdtlworld.com/update_sent_status/${letters[index].pk}/`,
//         { sent: 1 }
//       );
//     } catch (error) {
//       Swal.fire({
//         icon: "error",
//         title: "Email Not Sent!",
//         text: "The email has been not sent.",
//         timer: 4000,
//         timerProgressBar: true,
//         showConfirmButton: false,
//       });
//     } finally {
//       updatedSending[index] = false; // Set the loader to false
//       setIsSending({ ...updatedSending }); // Make sure to trigger the state update with the latest value

//     }
//   };

//   const downloadPdf = async (pdfPath, index) => {
//     const updatedDownloading = { ...isDownloading };
//     updatedDownloading[index] = true;
//     setIsDownloading(updatedDownloading);

//     try {
//       const response = await axios.get(
//         `https://tmaasbackend.tdtlworld.com/download_pdf/?pdfPath=${pdfPath}`,
//         { responseType: "blob" }
//       );
//       const url = window.URL.createObjectURL(new Blob([response.data]));
//       const link = document.createElement("a");
//       link.href = url;
//       link.setAttribute("download", "letter.pdf");
//       document.body.appendChild(link);
//       link.click();
//     } catch (error) {
//       console.error("Error downloading PDF:", error);
//     } finally {
//       const updatedDownloading = { ...isDownloading };
//       updatedDownloading[index] = false; // Stop the loader
//       setIsDownloading(updatedDownloading); // Update state
//     }
//   };

//   const [formData, setFormData] = useState({
//     candidateName: "",
//     position: "",
//     salary: "",
//     date: "",
//     section: "Offer Letter",
//   });
//   const [showModal, setShowModal] = useState(false);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     const formattedValue =
//       name === "date" ? new Date(value).toISOString().split("T")[0] : value;
//     setFormData((prevState) => ({
//       ...prevState,
//       [name]: formattedValue,
//     }));
//   };

//   const handleSubmit = async (e) => {
//     setIsLoading(true); // Start loading
//     e.preventDefault();
//     try {
//       await axios.post("https://tmaasbackend.tdtlworld.com/offer_letter/", formData);

//       if (formData.section === "Offer Letter") {
//         Swal.fire({
//           title: "Success!",
//           text: "Offer Letter generated successfully.",
//           icon: "success",
//           confirmButtonText: "OK",
//         }).then(() => {
//           navigate("/OfferLetter", { state: formData });
//         });
//       } else if (formData.section === "Rejection Letter") {
//         Swal.fire({
//           title: "Success!",
//           text: "Rejection Letter generated successfully.",
//           icon: "success",
//           confirmButtonText: "OK",
//         }).then(() => {
//           navigate("/letterform", { state: formData });
//           handleCloseModal();
//         });
//       }
//     } catch (error) {
//       Swal.fire({
//         title: "Error!",
//         text: "There was an error generating the letter. Please try again.",
//         icon: "error",
//         confirmButtonText: "OK",
//       });
//       console.error("Error generating offer letter:", error);
//     } finally {
//       // Stop the loading spinner
//       setIsLoading(false);
//     }
//   };

//   const handleOpenModal = () => {
//     setShowModal(true);
//   };

//   const handleCloseModal = () => {
//     setShowModal(false);
//   };
//   const formatDateTime = (dateTimeString) => {
//     const options = {
//       year: "numeric",
//       month: "long",
//       day: "numeric",
//       hour: "numeric",
//       minute: "2-digit",
//       hour12: true,
//     };
//     return new Date(dateTimeString).toLocaleString(undefined, options);
//   };
//   useEffect(() => {
//     const filteredData = letters.filter((letter) => {
//       const candidateMatches = letter.fields.candidate_name
//         .toLowerCase()
//         .includes(searchQuery.toLowerCase());
//       const typeMatches = letter.fields.LetterType.toLowerCase().includes(
//         searchQuery.toLowerCase()
//       );
//       return candidateMatches || typeMatches;
//     });
//     setFilteredLetters(filteredData);
//   }, [letters, searchQuery]);

//   const indexOfLastItem = currentPage * itemsPerPage;
//   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//   const currentItems = filteredLetters.slice(indexOfFirstItem, indexOfLastItem);

//   const handlePageChange = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };

//   return (
//     <>
//       <div
//         style={{ display: "flex", flexDirection: "column", height: "100vh" }}
//       >
//         <DashNavbar />
//         <div className="wrapper">
//           <Sidebar />
//           <div className="main">
//             <div className="container mt-2 " >
//               <div className="d-flex justify-content-center align-items-center letterpage" style={{ marginTop: "25px" }}>
//                 <div className="auth-inner w-100" style={{ padding: "10px 10px 10px 10px" }}>
//                   <div className="bg-white rounded ">
//                     <h2 className="display-6 gradient-text mb-4">Letters</h2>
//                     <div className="d-flex justify-content-center ">
//                       <div className="input-group search1 ">
//                         <div className="form-outline w-100 ">
//                           <input
//                             type="search"
//                             className="form-control"
//                             placeholder="Search by Candidate and Letter type"
//                             value={searchQuery}
//                             onChange={(e) => setSearchQuery(e.target.value)}
//                           />
//                           <button
//                             className="btn btn-primary searchicon"
//                             type="button"
//                           >
//                             <i className="fas fa-search"></i>
//                           </button>
//                         </div>
//                       </div>
//                     </div>

//                     <div className="d-flex justify-content-start">
//                       {" "}
//                       <Button
//                         onClick={handleOpenModal}
//                         className="btn btn-primary mb-3"
//                       >
//                         Create Letter +
//                       </Button>
//                     </div>
//                     <Table hover responsive="lg" size="lg">
//                       <thead className="thead-dark">
//                         <tr>
//                           <th>Sr.No</th>
//                           <th>Candidate Name</th>
//                           <th>Letter Type</th>
//                           <th>Letter PDF</th>
//                           <th>Time</th>
//                           <th>Send Mail</th>
//                           <th>Mail Status</th>
//                         </tr>
//                       </thead>
//                       <tbody>
//                         {currentItems.map((letter, index) => (
//                           <tr key={index}>
//                             <td>{index + 1}</td>
//                             <td>{letter.fields.candidate_name}</td>
//                             <td>{letter.fields.LetterType}</td>
//                             <td>
//                               <Button
//                                 onClick={() => downloadPdf(letter.fields.pdf_path, index)}
//                                 disabled={isDownloading[index] || letter.fields.LetterType === "Rejection Letter"}
//                               >
//                                 {isDownloading[index] ? (
//                                   <span
//                                     className="spinner-border spinner-border-sm"
//                                     role="status"
//                                     aria-hidden="true"
//                                   ></span>
//                                 ) : (
//                                   "Download"
//                                 )}
//                               </Button>
//                             </td>
//                             <td>{formatDateTime(letter.fields.Time)}</td>
//                             <td>
//                               <Button
//                                 onClick={() =>
//                                   sendEmail(
//                                     letter.fields.candidate_name,
//                                     letter.fields.pdf_path,
//                                     letter.fields.LetterType,
//                                     index
//                                   )
//                                 }
//                                 disabled={isSending[index] || sentStatus[letter.pk]}
//                               >
//                                 {isSending[index] ? (
//                                   <span
//                                     className="spinner-border spinner-border-sm"
//                                     role="status"
//                                     aria-hidden="true"
//                                   ></span>
//                                 ) : sentStatus[letter.pk] ? (
//                                   "Sent"
//                                 ) : (
//                                   "Send"
//                                 )}
//                               </Button>
//                             </td>
//                             <td>
//                               {sentStatus[letter.pk] ? (
//                                 <div style={{ fontSize: "28px" }}>
//                                   <FcApproval />{" "}
//                                 </div>
//                               ) : (
//                                 <div style={{ fontSize: "28px" }}>
//                                   <FcHighPriority />
//                                 </div>
//                               )}
//                             </td>
//                           </tr>
//                         ))}
//                       </tbody>
//                     </Table>
//                   </div>
//                   <Pagination>
//                     <Pagination.Prev
//                       onClick={() => handlePageChange(currentPage - 1)}
//                       disabled={currentPage === 1}
//                     />
//                     {Array.from(
//                       {
//                         length: Math.ceil(
//                           filteredLetters.length / itemsPerPage
//                         ),
//                       },
//                       (_, index) => (
//                         <Pagination.Item
//                           key={index + 1}
//                           active={index + 1 === currentPage}
//                           onClick={() => handlePageChange(index + 1)}
//                         >
//                           {index + 1}
//                         </Pagination.Item>
//                       )
//                     )}
//                     <Pagination.Next
//                       onClick={() => handlePageChange(currentPage + 1)}
//                       disabled={
//                         currentPage ===
//                         Math.ceil(filteredLetters.length / itemsPerPage)
//                       }
//                     />
//                   </Pagination>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <Modal
//           show={showModal}
//           onHide={handleCloseModal}
//           contentLabel="Offer Letter Form"
//           style={{
//             overlay: {
//               backgroundColor: "rgba(0, 0, 0, 0.5)",
//               zIndex: 1000,
//             },
//             content: {
//               position: "absolute",
//               top: "50%",
//               left: "50%",
//               transform: "translate(-50%, -50%)",
//               width: "60%",
//               maxWidth: "600px",
//               maxHeight: "80%",
//               padding: "20px",
//               borderRadius: "10px",
//               boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
//             },
//           }}
//         >
//           <Modal.Header closeButton>
//             <Modal.Title className=" gradient-text">Offer Letter Form</Modal.Title>
//           </Modal.Header>
//           <Modal.Body>
//             <form onSubmit={handleSubmit}>
//               <div className="mb-3">
//                 <label htmlFor="section" className="form-label">
//                   Select Section:
//                 </label>
//                 <select
//                   id="section"
//                   name="section"
//                   className="form-select"
//                   value={formData.section}
//                   onChange={handleChange}
//                 >
//                   <option value="Offer Letter">Offer Letter</option>
//                   <option value="Rejection Letter">Rejection Letter</option>
//                 </select>
//               </div>
//               {formData.section === "Offer Letter" && (
//                 <>
//                   <div className="mb-3">
//                     <label htmlFor="candidateName" className="form-label">
//                       Candidate Name:
//                     </label>
//                     <Select
//                       options={candidateNames.map((candidate) => ({
//                         value: candidate,
//                         label: candidate,
//                       }))}
//                       value={
//                         formData.candidateName
//                           ? {
//                             value: formData.candidateName,
//                             label: formData.candidateName,
//                           }
//                           : null
//                       }
//                       onChange={(selectedOption) =>
//                         setFormData({
//                           ...formData,
//                           candidateName: selectedOption?.value || "",
//                         })
//                       }
//                       placeholder="Select candidate"
//                       isSearchable={true}
//                       required
//                     />
//                   </div>
//                   <div className="mb-3">
//                     <label htmlFor="position" className="form-label">
//                       Position:
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="position"
//                       name="position"
//                       value={formData.position}
//                       onChange={handleChange}
//                       required
//                     />
//                   </div>
//                   <div className="mb-3">
//                     <label htmlFor="salary" className="form-label">
//                       Salary:
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="salary"
//                       name="salary"
//                       value={formData.salary}
//                       onChange={handleChange}
//                       required
//                     />
//                   </div>
//                   <div className="mb-3">
//                     <label htmlFor="date" className="form-label">
//                       Date:
//                     </label>
//                     <input
//                       type="date"
//                       className="form-control"
//                       id="date"
//                       name="date"
//                       value={formData.date}
//                       onChange={handleChange}
//                       required
//                     />
//                   </div>
//                 </>
//               )}

//               {formData.section === "Rejection Letter" && (
//                 <>
//                   <div className="mb-3">
//                     <label htmlFor="candidateName" className="form-label">
//                       Candidate Name:
//                     </label>
//                     <Select
//                       options={candidateNames.map((candidate) => ({
//                         value: candidate,
//                         label: candidate,
//                       }))}
//                       value={
//                         formData.candidateName
//                           ? {
//                             value: formData.candidateName,
//                             label: formData.candidateName,
//                           }
//                           : null
//                       }
//                       onChange={(selectedOption) =>
//                         setFormData({
//                           ...formData,
//                           candidateName: selectedOption?.value || "",
//                         })
//                       }
//                       placeholder="Select candidate"
//                       isSearchable={true}
//                       required
//                     />
//                   </div>
//                   <div className="mb-3">
//                     <label htmlFor="date" className="form-label">
//                       Date:
//                     </label>
//                     <input
//                       type="date"
//                       className="form-control"
//                       id="date"
//                       name="date"
//                       value={formData.date}
//                       onChange={handleChange}
//                       required
//                     />
//                   </div>
//                 </>
//               )}
//               <Button type="submit" className="btn btn-primary"
//                 disabled={isLoading} // Disable button while loading
//               >

//                 {isLoading ? (
//                   <span
//                     className="spinner-border spinner-border-sm"
//                     role="status"
//                     aria-hidden="true"
//                   ></span>
//                 ) : (
//                   " Generate"
//                 )}
//               </Button>
//             </form>
//           </Modal.Body>
//         </Modal>
//       </div>
//     </>
//   );
// };

// export default OfferLetterGenerator;


import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DashNavbar from "./DashNavbar";
import Sidebar from "./Sidebar";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import { Table, Pagination } from "react-bootstrap";
import Swal from "sweetalert2";
import { FcApproval, FcHighPriority } from "react-icons/fc";
import Select from "react-select";
import Spinner from "react-bootstrap/Spinner";

const OfferLetterGenerator = () => {
  const [letters, setLetters] = useState([]);
  const [candidateNames, setCandidateNames] = useState([]);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredLetters, setFilteredLetters] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [isLoading, setIsLoading] = useState(false);

  const [sentStatus, setSentStatus] = useState({});
  const [isDownloading, setIsDownloading] = useState({}); // State for download loaders
  const [isSending, setIsSending] = useState({}); // State for send loaders

  useEffect(() => {
    setIsLoading(true); // Start loading
    async function fetchLetters() {
      try {
        const response = await axios.get(
          "https://tmaasbackend.tdtlworld.com/get_letters/"
        );
        const data = response.data;
        if (data && Array.isArray(data.letters)) {
          setLetters(data.letters);
          initializeSentStatus(data.letters);
        } else {
          console.error("Invalid data format: letters field is not an array");
          setLetters([]);
        }
      } catch (error) {
        console.error("Error fetching letters:", error);
        setLetters([]);
      } finally {
        setIsLoading(false); // Stop loading, regardless of success or error
      }
    }
    async function fetchCandidateNames() {
      try {
        const response = await axios.get(
          "https://tmaasbackend.tdtlworld.com/get_candidate_names/"
        );
        const data = response.data;
        if (data && Array.isArray(data.candidate_names)) {
          setCandidateNames(data.candidate_names);
        } else {
          console.error(
            "Invalid data format: candidate_names field is not an array"
          );
          setCandidateNames([]);
        }
      } catch (error) {
        console.error("Error fetching candidate names:", error);
        setCandidateNames([]);
      } finally {
        setIsLoading(false); // Stop loading, regardless of success or error
      }
    }
    fetchLetters();
    fetchCandidateNames();
  }, []);

  const initializeSentStatus = (lettersData) => {
    const initialSentStatus = {};
    lettersData.forEach((letter) => {
      initialSentStatus[letter.pk] = letter.fields.sent == 1;
      console.log(letter.fields.sent);
    });
    setSentStatus(initialSentStatus);
    console.log(initialSentStatus);
  };
  const sendEmail = async (candidateName, pdfPath, LetterType, index) => {
    const updatedSending = { ...isSending };
    updatedSending[index] = true;
    setIsSending(updatedSending);

    try {
      const response = await axios.post(
        "https://tmaasbackend.tdtlworld.com/send_offer_letter_email/",
        {
          candidate_name: candidateName,
          pdf_path: pdfPath,
          letter_type: LetterType,
        }
      );
      Swal.fire({
        icon: "success",
        title: "Email Sent!",
        text: "The email has been successfully sent.",
        timer: 1000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
      const updatedSentStatus = { ...sentStatus };
      updatedSentStatus[letters[index].pk] = true;
      setSentStatus(updatedSentStatus);

      await axios.put(
        `https://tmaasbackend.tdtlworld.com/update_sent_status/${letters[index].pk}/`,
        { sent: 1 }
      );
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Email Not Sent!",
        text: "The email has been not sent.",
        timer: 4000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } finally {
      updatedSending[index] = false; // Set the loader to false
      setIsSending({ ...updatedSending }); // Make sure to trigger the state update with the latest value
    }
  };

  const downloadPdf = async (pdfPath, index) => {
    const updatedDownloading = { ...isDownloading };
    updatedDownloading[index] = true;
    setIsDownloading(updatedDownloading);

    try {
      const response = await axios.get(
        `https://tmaasbackend.tdtlworld.com/download_pdf/?pdfPath=${pdfPath}`,
        { responseType: "blob" }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "letter.pdf");
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading PDF:", error);
    } finally {
      const updatedDownloading = { ...isDownloading };
      updatedDownloading[index] = false; // Stop the loader
      setIsDownloading(updatedDownloading); // Update state
    }
  };

  const [formData, setFormData] = useState({
    candidateName: "",
    position: "",
    salary: "",
    date: "",
    section: "Offer Letter",
  });
  const [showModal, setShowModal] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const formattedValue =
      name === "date" ? new Date(value).toISOString().split("T")[0] : value;
    setFormData((prevState) => ({
      ...prevState,
      [name]: formattedValue,
    }));
  };

  const handleSubmit = async (e) => {
    setIsLoading(true); // Start loading
    e.preventDefault();
    try {
      await axios.post(
        "https://tmaasbackend.tdtlworld.com/offer_letter/",
        formData
      );

      if (formData.section === "Offer Letter") {
        Swal.fire({
          title: "Success!",
          text: "Offer Letter generated successfully.",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          navigate("/OfferLetter", { state: formData });
        });
      } else if (formData.section === "Rejection Letter") {
        Swal.fire({
          title: "Success!",
          text: "Rejection Letter generated successfully.",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          navigate("/letterform", { state: formData });
          handleCloseModal();
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "There was an error generating the letter. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
      console.error("Error generating offer letter:", error);
    } finally {
      // Stop the loading spinner
      setIsLoading(false);
    }
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const formatDateTime = (dateTimeString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };
    return new Date(dateTimeString).toLocaleString(undefined, options);
  };
  useEffect(() => {
    const filteredData = letters.filter((letter) => {
      const candidateMatches = letter.fields.candidate_name
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
      const typeMatches = letter.fields.LetterType.toLowerCase().includes(
        searchQuery.toLowerCase()
      );
      return candidateMatches || typeMatches;
    });
    setFilteredLetters(filteredData);
  }, [letters, searchQuery]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredLetters.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <div
        style={{ display: "flex", flexDirection: "column", height: "100vh" }}
      >
        <DashNavbar />
        <div className="wrapper">
          <Sidebar />
          <div className="main">
            <div className="container mt-2 ">
              <div
                className="d-flex justify-content-center align-items-center letterpage"
                style={{ marginTop: "25px" }}
              >
                <div
                  className="auth-inner w-100"
                  style={{ padding: "10px 10px 10px 10px" }}
                >
                  <div className="bg-white rounded ">
                    <h2 className="display-6 gradient-text mb-4">Letters</h2>
                    <div className="d-flex justify-content-center ">
                      <div className="input-group search1 ">
                        <div className="form-outline w-100 ">
                          <input
                            type="search"
                            className="form-control"
                            placeholder="Search by Candidate and Letter type"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                          />
                          <button
                            className="btn btn-primary searchicon"
                            type="button"
                          >
                            <i className="fas fa-search"></i>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-start">
                      {" "}
                      <Button
                        onClick={handleOpenModal}
                        className="btn btn-primary mb-3"
                      >
                        Create Letter +
                      </Button>
                    </div>
                    <div style={{ position: "relative", minHeight: "300px" }}>
                      {isLoading ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: "rgba(255, 255, 255, 0.8)", // Optional: semi-transparent background
                            zIndex: 10, // Ensures loader is above table
                          }}
                        >
                          <Spinner
                            animation="border"
                            role="status"
                            variant="primary"
                          >
                            {/* <span className="sr-only">Loading...</span> */}
                          </Spinner>
                        </div>
                      ) : (
                        <Table hover responsive="lg" size="lg">
                          <thead className="thead-dark">
                            <tr>
                              <th>Sr.No</th>
                              <th>Candidate Name</th>
                              <th>Letter Type</th>
                              <th>Letter PDF</th>
                              <th>Time</th>
                              <th>Send Mail</th>
                              <th>Mail Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentItems.map((letter, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{letter.fields.candidate_name}</td>
                                <td>{letter.fields.LetterType}</td>
                                <td>
                                  <Button
                                    onClick={() =>
                                      downloadPdf(letter.fields.pdf_path, index)
                                    }
                                    disabled={
                                      isDownloading[index] ||
                                      letter.fields.LetterType ===
                                      "Rejection Letter"
                                    }
                                  >
                                    {isDownloading[index] ? (
                                      <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                    ) : (
                                      "Download"
                                    )}
                                  </Button>
                                </td>
                                <td>{formatDateTime(letter.fields.Time)}</td>
                                <td>
                                  <Button
                                    onClick={() =>
                                      sendEmail(
                                        letter.fields.candidate_name,
                                        letter.fields.pdf_path,
                                        letter.fields.LetterType,
                                        index
                                      )
                                    }
                                    disabled={
                                      isSending[index] || sentStatus[letter.pk]
                                    }
                                  >
                                    {isSending[index] ? (
                                      <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                    ) : sentStatus[letter.pk] ? (
                                      "Sent"
                                    ) : (
                                      "Send"
                                    )}
                                  </Button>
                                </td>
                                <td>
                                  {sentStatus[letter.pk] ? (
                                    <div style={{ fontSize: "28px" }}>
                                      <FcApproval />{" "}
                                    </div>
                                  ) : (
                                    <div style={{ fontSize: "28px" }}>
                                      <FcHighPriority />
                                    </div>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      )}
                    </div>
                  </div>
                  <Pagination>
                    <Pagination.Prev
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    />
                    {Array.from(
                      {
                        length: Math.ceil(
                          filteredLetters.length / itemsPerPage
                        ),
                      },
                      (_, index) => (
                        <Pagination.Item
                          key={index + 1}
                          active={index + 1 === currentPage}
                          onClick={() => handlePageChange(index + 1)}
                        >
                          {index + 1}
                        </Pagination.Item>
                      )
                    )}
                    <Pagination.Next
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={
                        currentPage ===
                        Math.ceil(filteredLetters.length / itemsPerPage)
                      }
                    />
                  </Pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={showModal}
          onHide={handleCloseModal}
          contentLabel="Offer Letter Form"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 1000,
            },
            content: {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "60%",
              maxWidth: "600px",
              maxHeight: "80%",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
            },
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title className=" gradient-text">
              Offer Letter Form
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="section" className="form-label">
                  Select Section:
                </label>
                <select
                  id="section"
                  name="section"
                  className="form-select"
                  value={formData.section}
                  onChange={handleChange}
                >
                  <option value="Offer Letter">Offer Letter</option>
                  <option value="Rejection Letter">Rejection Letter</option>
                </select>
              </div>
              {formData.section === "Offer Letter" && (
                <>
                  <div className="mb-3">
                    <label htmlFor="candidateName" className="form-label">
                      Candidate Name:
                    </label>
                    <Select
                      options={candidateNames.map((candidate) => ({
                        value: candidate,
                        label: candidate,
                      }))}
                      value={
                        formData.candidateName
                          ? {
                            value: formData.candidateName,
                            label: formData.candidateName,
                          }
                          : null
                      }
                      onChange={(selectedOption) =>
                        setFormData({
                          ...formData,
                          candidateName: selectedOption?.value || "",
                        })
                      }
                      placeholder="Select candidate"
                      isSearchable={true}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="position" className="form-label">
                      Position:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="position"
                      name="position"
                      value={formData.position}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="salary" className="form-label">
                      Salary:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="salary"
                      name="salary"
                      value={formData.salary}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="date" className="form-label">
                      Date:
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="date"
                      name="date"
                      value={formData.date}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </>
              )}

              {formData.section === "Rejection Letter" && (
                <>
                  <div className="mb-3">
                    <label htmlFor="candidateName" className="form-label">
                      Candidate Name:
                    </label>
                    <Select
                      options={candidateNames.map((candidate) => ({
                        value: candidate,
                        label: candidate,
                      }))}
                      value={
                        formData.candidateName
                          ? {
                            value: formData.candidateName,
                            label: formData.candidateName,
                          }
                          : null
                      }
                      onChange={(selectedOption) =>
                        setFormData({
                          ...formData,
                          candidateName: selectedOption?.value || "",
                        })
                      }
                      placeholder="Select candidate"
                      isSearchable={true}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="date" className="form-label">
                      Date:
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="date"
                      name="date"
                      value={formData.date}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </>
              )}
              <Button
                type="submit"
                className="btn btn-primary"
                disabled={isLoading} // Disable button while loading
              >
                {isLoading ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  " Generate"
                )}
              </Button>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default OfferLetterGenerator;