// import React, { useState } from "react";
// import axios from "axios";
// import Swal from "sweetalert2";
// import { useNavigate } from "react-router-dom";
// import AuthService from "./authService";
// import Navbar from "../Misc/Navbar";
// import { Link } from "react-router-dom";
// import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons
// import log_in from "../../Assets/login.png";
// import "../Authentication/login.component.css"; // Import the CSS file

// export default function Login() {
//   const navigate = useNavigate();

//   const [formData, setFormData] = useState({
//     email: "",
//     password: "",
//     role: "",
//   });

//   const [showPassword, setShowPassword] = useState(false); // State for password visibility

//   const handleInputChange = (event) => {
//     const { name, value } = event.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleLogin = (event) => {
//     event.preventDefault();

//     const loginData = {
//       email: formData.email,
//       password: formData.password,
//     };

//     AuthService.login(loginData.email, loginData.password);

//     axios
//       .post("https://tmaasbackend.tdtlworld.com/Login_view/", loginData)
//       .then((response) => {
//         const { user, message, error_message } = response.data;

//         if (error_message) {
//           Swal.fire({
//             icon: "error",
//             title: "Login Error",
//             text: error_message,
//             timer: 4000,
//             timerProgressBar: true,
//             showCancelButton: false,
//           });
//         } else if (user) {
//           const { role } = user;
//           const userName = user.name;
//           const userId = user.id;
//           localStorage.setItem("User_Role", role);
//           localStorage.setItem("UserName", userName);
//           localStorage.setItem("user_id", userId);
//           localStorage.setItem("email", formData.email);
//           Swal.fire({
//             icon: "success",
//             title: "Login Successful!",
//             text: message || "Welcome!",
//             timer: 1000,
//             timerProgressBar: true,
//             showConfirmButton: false,
//           }).then(() => {
//             if (role === "Admin") {
//               navigate("/Admindashboard");
//             } else if (role === "Candidate") {
//               navigate("/dashboard");
//             } else if (role === "Interviewer") {
//               navigate("/InterviewerDashboard");
//             } else if (role === "HR") {
//               navigate("/HRDashboard");
//             }
//           });
//         }
//       })
//       .catch((error) => {
//         if (error.response.status === 403) {
//           Swal.fire({
//             title: "Login Error",
//             html: '<span style="color: red;">Your account is inactive. Please contact the administrator.</span>',
//             icon: "warning",
//             timer: 5000,
//             timerProgressBar: true,
//           });
//         } else {
//           Swal.fire({
//             icon: "error",
//             title: "Login Error",
//             text: "Invalid email or password. Please try again.",
//             timer: 4000,
//             timerProgressBar: true,
//           });
//         }
//       });
//   };

//   return (
//     <div className="fullscreen-background">
//       <Navbar />
//       <div className="form-wrapper">
//         <div className="signupLogin-box">
//           <form onSubmit={handleLogin}>
//             <h3 className="form-title">Sign In</h3>

//             <div className="input-container mb-3">
//               <div className="icon-container">
//                 <i className="fa-solid fa-user"></i>
//               </div>
//               <input
//                 type="email"
//                 autoComplete="off"
//                 className="email-input"
//                 required
//                 placeholder="Enter Email"
//                 name="email"
//                 onChange={handleInputChange}
//                 value={formData.email}
//               />
//             </div>

//             <div className="input-container">
//               <div className="icon-container">
//                 <i className="fa-solid fa-lock"></i>
//               </div>
//               <input
//                 type={showPassword ? "text" : "password"} // Toggle between text and password
//                 className="email-input"
//                 required
//                 placeholder="Enter Password"
//                 name="password"
//                 onChange={handleInputChange}
//                 value={formData.password}
//               />
//               {/* Eye icon to toggle password visibility */}
//               <div
//                 className="eye-icon"
//                 onClick={() => setShowPassword(!showPassword)} // Toggle visibility on click
//                 style={{
//                   cursor: "pointer",
//                   position: "absolute",
//                   right: "30px",
//                   top: "10px",
//                   fontSize: "25px", // Adjust icon size if needed
//                 }}
//               >
//                 {showPassword ? <FaEyeSlash /> : <FaEye />} {/* Show or hide icon */}
//               </div>
//             </div>

//             <div className="mb-3 d-flex flex-column align-items-start">
//               <div className="custom-control custom-checkbox">
//                 <input
//                   type="checkbox"
//                   className="custom-control-input"
//                   id="customCheck1"
//                 />
//                 <label className="custom-control-label" htmlFor="customCheck1">
//                   Remember me
//                 </label>
//               </div>
//             </div>

//             <div className="d-grid">
//               <button type="submit" className="btn-shiny2" style={{ margin: "auto auto" }}>
//                 LOGIN
//               </button>
//             </div>

//             <p className="forgot-password text-center">
//               Forgot{" "}
//               <Link to="/forgotPass" style={{ color: "grey" }}>
//                 password?
//               </Link>
//             </p>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// }


import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import AuthService from "./authService";
import Navbar from "../Misc/Navbar";
import { Link } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "../Authentication/login.component.css";

export default function Login() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    role: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false); // New state for loading

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleLogin = (event) => {
    event.preventDefault();

    setLoading(true); // Set loading to true

    const loginData = {
      email: formData.email,
      password: formData.password,
    };

    AuthService.login(loginData.email, loginData.password);

    axios
      .post("https://tmaasbackend.tdtlworld.com/Login_view/", loginData)
      .then((response) => {
        const { user, message, error_message } = response.data;

        if (error_message) {
          Swal.fire({
            icon: "error",
            title: "Login Error",
            text: error_message,
            timer: 4000,
            timerProgressBar: true,
            showCancelButton: false,
          });
        } else if (user) {
          const { role } = user;
          const userName = user.name;
          const userId = user.id;
          localStorage.setItem("User_Role", role);
          localStorage.setItem("UserName", userName);
          localStorage.setItem("user_id", userId);
          localStorage.setItem("email", formData.email);
          Swal.fire({
            icon: "success",
            title: "Login Successful!",
            text: message || "Welcome!",
            timer: 1000,
            timerProgressBar: true,
            showConfirmButton: false,
          }).then(() => {
            if (role === "Admin") {
              navigate("/Admindashboard");
            } else if (role === "Candidate") {
              navigate("/dashboard");
            } else if (role === "Interviewer") {
              navigate("/InterviewerDashboard");
            } else if (role === "HR") {
              navigate("/HRDashboard");
            }
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          Swal.fire({
            title: "Login Error",
            html: '<span style="color: red;">Your account is inactive. Please contact the administrator.</span>',
            icon: "warning",
            timer: 5000,
            timerProgressBar: true,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Login Error",
            text: "Invalid email or password. Please try again.",
            timer: 4000,
            timerProgressBar: true,
          });
        }
      })
      .finally(() => {
        setLoading(false); // Reset loading state
      });
  };

  return (
    <div className="fullscreen-background">
      <Navbar />
      <div className="form-wrapper">
        <div className="signupLogin-box">
          <form onSubmit={handleLogin}>
            <h3 className="form-title">Sign In</h3>

            <div className="input-container mb-3">
              <div className="icon-container">
                <i className="fa-solid fa-user"></i>
              </div>
              <input
                type="email"
                autoComplete="off"
                className="email-input"
                required
                placeholder="Enter Email"
                name="email"
                onChange={handleInputChange}
                value={formData.email}
              />
            </div>

            <div className="input-container">
              <div className="icon-container">
                <i className="fa-solid fa-lock"></i>
              </div>
              <input
                type={showPassword ? "text" : "password"}
                className="email-input"
                required
                placeholder="Enter Password"
                name="password"
                onChange={handleInputChange}
                value={formData.password}
              />
              <div
                className="eye-icon"
                onClick={() => setShowPassword(!showPassword)}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  right: "30px",
                  top: "10px",
                  fontSize: "25px",
                }}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </div>
            </div>

            {/* <div className="mb-3 d-flex flex-column align-items-start">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customCheck1"
                />
                <label className="custom-control-label" htmlFor="customCheck1">
                  Remember me
                </label>
              </div>
            </div> */}
            <div className="mb-3 d-flex flex-column align-items-start" style={{ marginTop: '15px' }}>
              <div
                className="custom-control custom-checkbox d-flex align-items-center"
                style={{
                  padding: '10px',
                }}
              >
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customCheck1"
                  style={{ marginRight: '10px', transform: 'scale(1.2)' }} // Larger checkbox
                />
                <label
                  className="custom-control-label"
                  htmlFor="customCheck1"
                  style={{
                    fontSize: '16px',
                    fontWeight: '500',
                    color: '#333',
                    cursor: 'pointer',
                  }}
                >
                  Remember me
                </label>
              </div>
            </div>



            <div className="d-grid">
              <button
                type="submit"
                className="btn-shiny2"
                style={{ margin: "auto auto", position: "relative" }}
                disabled={loading} // Disable the button while loading
              >
                {loading ? (
                  <span className="spinner-border spinner-border-sm"></span>
                ) : (
                  "LOGIN"
                )}
              </button>
            </div>

            <p className="forgot-password text-center">
              Forgot{" "}
              <Link to="/forgotPass" style={{ color: "grey" }}>
                password?
              </Link>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
}
