import React, { useState, useEffect } from "react";
import axios from "axios";
import DashNavbar from "./DashNavbar";
import Sidebar from "./Sidebar";
import Pagination from "react-bootstrap/Pagination";
import Modal from "react-bootstrap/Modal";
import { FcCancel, FcApproval } from "react-icons/fc";
import { IoWarningOutline } from "react-icons/io5";
import Spinner from "react-bootstrap/Spinner"; // Import Spinner for loading indicators

function DocumentViewList() {
  const [documents, setDocuments] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const itemsPerPage = 5;
  const [isLoading, setIsLoading] = useState(false);
  const [viewLoading, setViewLoading] = useState({});
  const [buttonLoading, setButtonLoading] = useState({});

  useEffect(() => {
    fetchDocuments();
  }, [currentPage, searchTerm]);

  const fetchDocuments = async () => {
    setIsLoading(true); // Start loading
    try {
      const response = await axios.get(
        `https://tmaasbackend.tdtlworld.com/api/documents/?page=${currentPage}`
      );
      setDocuments(response.data);
      filterDocuments(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false); // Stop the loading spinner
    }
  };

  const filterDocuments = (data) => {
    const filtered = data.filter(
      (document) =>
        document.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
        document.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredDocuments(filtered);
  };

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) {
      return;
    }
    setCurrentPage(page);
  };

  const totalPages = Math.ceil(filteredDocuments.length / itemsPerPage);
  const paginatedDocuments = filteredDocuments.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleViewDetails = (document) => {
    setSelectedDocument(document);
  };

  const handleCloseDetails = () => {
    setSelectedDocument(null);
  };

  const handleOpenDocument = async (documentType) => {
    setViewLoading((prev) => ({ ...prev, [documentType]: true }));
    try {
      const response = await axios.post(
        "https://tmaasbackend.tdtlworld.com/api/view_document/",
        { email: selectedDocument.email, document_type: documentType },
        { responseType: "blob" }
      );

      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      window.open(url, "_blank");
    } catch (error) {
      console.error("Error opening document:", error);
    } finally {
      setViewLoading((prev) => ({ ...prev, [documentType]: false }));
    }
  };

  const handleAcceptReject = async (documentType, status) => {
    setButtonLoading((prev) => ({
      ...prev,
      [`${documentType}_${status}`]: true,
    }));

    try {
      await axios.post(
        "https://tmaasbackend.tdtlworld.com/api/update_document_status/",
        {
          email: selectedDocument.email,
          document_type: documentType,
          status: status,
        }
      );

      setSelectedDocument((prev) => ({
        ...prev,
        [`${documentType}_status`]: status,
      }));

      fetchDocuments();
    } catch (error) {
      console.error("Error updating status:", error);
    } finally {
      setButtonLoading((prev) => ({
        ...prev,
        [`${documentType}_${status}`]: false,
      }));
    }
  };

  const iconStyle = { fontSize: "200%" };

  return (
    <>
      <DashNavbar />
      <div className="wrapper">
        <Sidebar />
        <div className="main">
          <div className="container" style={{ marginTop: "25px" }}>
            <div
              className="auth-inner mt-2 w-100"
              style={{ padding: "10px 10px 10px 10px" }}
            >
              <h2 className="mb-4 gradient-text">Document View List</h2>
<div className="container">
  <div className="row">
    <div className="col-12 col-sm-6 mx-auto mb-2">
      <div className="form-outline">
        <input
          type="search"
          className="form-control"
          placeholder="Search by Name or Email"
          value={searchTerm} // Ensure searchTerm is defined
          onChange={handleSearch} // Ensure handleSearch is defined
        />
        <button className="btn btn-primary searchicon text-xs">
          <i className="fas fa-search"></i>
        </button>
      </div>
    </div>
  </div>
</div>

              <div className="table-responsive">
                <table className="table">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Sr.No</th>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td colSpan="4" className="text-center">
                          <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </td>
                      </tr>
                    ) : paginatedDocuments.length > 0 ? (
                      paginatedDocuments.map((document, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{document.username}</td>
                          <td>{document.email}</td>
                          <td>
                            <button
                              className="btn btn-primary btn-sm"
                              onClick={() => handleViewDetails(document)}
                            >
                              View
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center">
                          No documents found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <div className="d-flex justify-content-start mt-4">
                <Pagination>
                  <Pagination.Prev
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  />
                  {Array.from({ length: totalPages }, (_, index) => (
                    <Pagination.Item
                      key={index + 1}
                      active={index + 1 === currentPage}
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  />
                </Pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
      {selectedDocument && (
        <Modal show={true} onHide={handleCloseDetails} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>
              {selectedDocument.username} ({selectedDocument.email})
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Document Name</th>
                  <th scope="col">View PDF</th>
                  <th scope="col">Action</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                {["aadhar", "pan", "hsc", "ssc", "degree", "other"].map((docType) => (
                  <tr key={docType}>
                    <td>{docType.toUpperCase()}</td>
                    <td>
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() => handleOpenDocument(docType)}
                        disabled={!selectedDocument[docType] || viewLoading[docType]}
                      >
                        {viewLoading[docType] ? <Spinner size="sm" /> : "View PDF"}
                      </button>
                    </td>
                    <td>
                      <button
                        className="me-2 btn btn-success btn-sm"
                        onClick={() => handleAcceptReject(docType, "Y")}
                        disabled={
                          !selectedDocument[docType] ||
                          buttonLoading[`${docType}_Y`]
                        }
                      >
                        {buttonLoading[`${docType}_Y`] ? (
                          <Spinner size="sm" />
                        ) : (
                          "Accept"
                        )}
                      </button>
                      <button
                        className="me-2 btn btn-danger btn-sm"
                        onClick={() => handleAcceptReject(docType, "N")}
                        disabled={
                          !selectedDocument[docType] ||
                          buttonLoading[`${docType}_N`]
                        }
                      >
                        {buttonLoading[`${docType}_N`] ? (
                          <Spinner size="sm" />
                        ) : (
                          "Reject"
                        )}
                      </button>
                    </td>
                    <td>
                      {selectedDocument[`${docType}_status`] === "Y" && (
                        <FcApproval style={iconStyle} />
                      )}
                      {selectedDocument[`${docType}_status`] === "N" && (
                        <FcCancel style={iconStyle} />
                      )}
                      {!selectedDocument[`${docType}_status`] && (
                        <IoWarningOutline style={iconStyle} />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

export default DocumentViewList;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import DashNavbar from "./DashNavbar";
// import Sidebar from "./Sidebar";
// import Pagination from "react-bootstrap/Pagination";
// import Modal from "react-bootstrap/Modal";
// import { FcCancel, FcApproval } from "react-icons/fc";
// import { IoWarningOutline } from "react-icons/io5";
// import Spinner from "react-bootstrap/Spinner"; // Import Spinner for loading indicators

// function DocumentViewList() {
//   const [documents, setDocuments] = useState([]);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [filteredDocuments, setFilteredDocuments] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [selectedDocument, setSelectedDocument] = useState(null);
//   const itemsPerPage = 5;
//   const [isLoading, setIsLoading] = useState(false);
//   const [viewLoading, setViewLoading] = useState({});
//   const [buttonLoading, setButtonLoading] = useState({});

//   useEffect(() => {
//     fetchDocuments();
//   }, [currentPage, searchTerm]);

//   const fetchDocuments = async () => {
//     setIsLoading(true); // Start loading
//     try {
//       const response = await axios.get(
//         `https://tmaasbackend.tdtlworld.com/api/documents/?page=${currentPage}`
//       );
//       setDocuments(response.data);
//       filterDocuments(response.data);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     } finally {
//       setIsLoading(false); // Stop the loading spinner
//     }
//   };

//   const filterDocuments = (data) => {
//     const filtered = data.filter(
//       (document) =>
//         document.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
//         document.email.toLowerCase().includes(searchTerm.toLowerCase())
//     );
//     setFilteredDocuments(filtered);
//   };

//   const handlePageChange = (page) => {
//     if (page < 1 || page > totalPages) {
//       return;
//     }
//     setCurrentPage(page);
//   };

//   const totalPages = Math.ceil(filteredDocuments.length / itemsPerPage);
//   const paginatedDocuments = filteredDocuments.slice(
//     (currentPage - 1) * itemsPerPage,
//     currentPage * itemsPerPage
//   );

//   const handleSearch = (e) => {
//     setSearchTerm(e.target.value);
//     setCurrentPage(1);
//   };

//   const handleViewDetails = (document) => {
//     setSelectedDocument(document);
//   };

//   const handleCloseDetails = () => {
//     setSelectedDocument(null);
//   };

//   const handleOpenDocument = async (documentType) => {
//     setViewLoading((prev) => ({ ...prev, [documentType]: true }));
//     try {
//       const response = await axios.post(
//         "https://tmaasbackend.tdtlworld.com/api/view_document/",
//         { email: selectedDocument.email, document_type: documentType },
//         { responseType: "blob" }
//       );

//       const url = window.URL.createObjectURL(
//         new Blob([response.data], { type: "application/pdf" })
//       );
//       window.open(url, "_blank");
//     } catch (error) {
//       console.error("Error opening document:", error);
//     } finally {
//       setViewLoading((prev) => ({ ...prev, [documentType]: false }));
//     }
//   };

//   const handleAcceptReject = async (documentType, status) => {
//     setButtonLoading((prev) => ({
//       ...prev,
//       [`${documentType}_${status}`]: true,
//     }));

//     try {
//       await axios.post(
//         "https://tmaasbackend.tdtlworld.com/api/update_document_status/",
//         {
//           email: selectedDocument.email,
//           document_type: documentType,
//           status: status,
//         }
//       );

//       setSelectedDocument((prev) => ({
//         ...prev,
//         [`${documentType}_status`]: status,
//       }));

//       fetchDocuments();
//     } catch (error) {
//       console.error("Error updating status:", error);
//     } finally {
//       setButtonLoading((prev) => ({
//         ...prev,
//         [`${documentType}_${status}`]: false,
//       }));
//     }
//   };

//   const iconStyle = { fontSize: "200%" };

//   return (
//     <>
//       <DashNavbar />
//       <div className="wrapper">
//         <Sidebar />
//         <div className="main">
//           <div className="container" style={{ marginTop: "25px" }}>
//             <div
//               className="auth-inner mt-2 w-100"
//               style={{ padding: "10px 10px 10px 10px" }}
//             >
//               <h2 className="mb-4 gradient-text">Document View List</h2>
//               <div className="input-group justify-content-center align-items-center">
//                 <div className="form-outline w-50">
//                   <input
//                     type="search"
//                     className="form-control"
//                     placeholder="Search by Name or Email"
//                     value={searchTerm}
//                     onChange={handleSearch}
//                   />
//                   <button className="btn btn-primary searchicon text-xs">
//                     <i className="fas fa-search"></i>
//                   </button>
//                 </div>
//               </div>
//               <div className="table-responsive">
//                 <table className="table">
//                   <thead className="thead-dark">
//                     <tr>
//                       <th scope="col">Sr.No</th>
//                       <th scope="col">Name</th>
//                       <th scope="col">Email</th>
//                       <th scope="col">Actions</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {isLoading ? (
//                       <tr>
//                         <td colSpan="4" className="text-center">
//                           <div className="spinner-border text-primary" role="status">
//                             <span className="sr-only">Loading...</span>
//                           </div>
//                         </td>
//                       </tr>
//                     ) : paginatedDocuments.length > 0 ? (
//                       paginatedDocuments.map((document, index) => (
//                         <tr key={index}>
//                           <td>{index + 1}</td>
//                           <td>{document.username}</td>
//                           <td>{document.email}</td>
//                           <td>
//                             <button
//                               className="btn btn-primary btn-sm"
//                               onClick={() => handleViewDetails(document)}
//                             >
//                               View
//                             </button>
//                           </td>
//                         </tr>
//                       ))
//                     ) : (
//                       <tr>
//                         <td colSpan="4" className="text-center">
//                           No documents found.
//                         </td>
//                       </tr>
//                     )}
//                   </tbody>
//                 </table>
//               </div>

//               <div className="d-flex justify-content-start mt-4">
//                 <Pagination>
//                   <Pagination.Prev
//                     onClick={() => handlePageChange(currentPage - 1)}
//                     disabled={currentPage === 1}
//                   />
//                   {Array.from({ length: totalPages }, (_, index) => (
//                     <Pagination.Item
//                       key={index + 1}
//                       active={index + 1 === currentPage}
//                       onClick={() => handlePageChange(index + 1)}
//                     >
//                       {index + 1}
//                     </Pagination.Item>
//                   ))}
//                   <Pagination.Next
//                     onClick={() => handlePageChange(currentPage + 1)}
//                     disabled={currentPage === totalPages}
//                   />
//                 </Pagination>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       {selectedDocument && (
//         <Modal show={true} onHide={handleCloseDetails} size="lg">
//           <Modal.Header closeButton>
//             <Modal.Title>
//               {selectedDocument.username} ({selectedDocument.email})
//             </Modal.Title>
//           </Modal.Header>
//           <Modal.Body>
//             <table className="table">
//               <thead>
//                 <tr>
//                   <th scope="col">Document Name</th>
//                   <th scope="col">View PDF</th>
//                   <th scope="col">Action</th>
//                   <th scope="col">Status</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {["aadhar", "pan", "hsc", "ssc", "degree", "other"].map((docType) => (
//                   <tr key={docType}>
//                     <td>{docType.toUpperCase()}</td>
//                     <td>
//                       <button
//                         className="btn btn-primary btn-sm"
//                         onClick={() => handleOpenDocument(docType)}
//                         disabled={!selectedDocument[docType] || viewLoading[docType]}
//                       >
//                         {viewLoading[docType] ? <Spinner size="sm" /> : "View PDF"}
//                       </button>
//                     </td>
//                     <td>
//                       <button
//                         className="me-2 btn btn-success btn-sm"
//                         onClick={() => handleAcceptReject(docType, "Y")}
//                         disabled={
//                           !selectedDocument[docType] ||
//                           buttonLoading[`${docType}_Y`]
//                         }
//                       >
//                         {buttonLoading[`${docType}_Y`] ? (
//                           <Spinner size="sm" />
//                         ) : (
//                           "Accept"
//                         )}
//                       </button>
//                       <button
//                         className="me-2 btn btn-danger btn-sm"
//                         onClick={() => handleAcceptReject(docType, "N")}
//                         disabled={
//                           !selectedDocument[docType] ||
//                           buttonLoading[`${docType}_N`]
//                         }
//                       >
//                         {buttonLoading[`${docType}_N`] ? (
//                           <Spinner size="sm" />
//                         ) : (
//                           "Reject"
//                         )}
//                       </button>
//                     </td>
//                     <td>
//                       {selectedDocument[`${docType}_status`] === "Y" && (
//                         <FcApproval style={iconStyle} />
//                       )}
//                       {selectedDocument[`${docType}_status`] === "N" && (
//                         <FcCancel style={iconStyle} />
//                       )}
//                       {!selectedDocument[`${docType}_status`] && (
//                         <IoWarningOutline style={iconStyle} />
//                       )}
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </Modal.Body>
//         </Modal>
//       )}
//     </>
//   );
// }

// export default DocumentViewList;


