// import React, { Component } from "react";
// import "../css/dash.css";
// import Navbar from "./Navbar";
// import { Link } from "react-router-dom";
// import BgVideo from "../../Assets/bgvideo.mp4";
// export default class Home extends Component {
//   render() {
//     return (
//       <>
//       <div className="home-outer-div">
//         <Navbar />
//           <div className="content">
//             <video loop muted autoPlay playsInline className="bgvideo">
//               <source src={BgVideo} type="video/mp4"/>
//             </video>
//             <h1 className=" text-light">Welcome to TMaaS</h1>
//             <p className="lead  text-light">Made by The Data Tech Labs</p>
//             <Link className="btn start-here-btn text-light" to="/sign-up">
//               Start Here!
//             </Link>
//           </div>
//       </div>

//       </>
//     );
//   }
// }

import React, { Component } from "react";
import "../css/dash.css";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import BgVideo from "../../Assets/bgvideo.mp4";

export default class Home extends Component {
  render() {
    return (
      <>
        <div className="home-outer-div">
          <Navbar />
          <div className="content d-flex flex-column align-items-center justify-content-center text-center vh-100">
            <video
              loop
              muted
              autoPlay
              playsInline
              className="bgvideo position-absolute w-100 h-100"
              style={{ objectFit: "cover", zIndex: "-1" }}
            >
              <source src={BgVideo} type="video/mp4" />
            </video>
            <h1 className="text-light display-4 mb-3">Welcome to TMaaS</h1>
            <p className="lead text-light mb-4">Made by The Data Tech Labs</p>
            <Link
              className="btn btn-primary btn-lg start-here-btn text-light"
              to="/sign-up"
            >
              Start Here!
            </Link>
          </div>
        </div>
      </>
    );
  }
}
