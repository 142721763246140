import React, { useState } from "react";
import axios from "axios";
import DashNavbar from "./DashNavbar";
import "../css/dash.css";
import Swal from "sweetalert2";
import Sidebar from "./Sidebar";

export default function Feedback() {
  const [rating, setRating] = useState("");
  const [feedback, setFeedback] = useState("");
  const [ratingError, setRatingError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleRatingChange = (event) => {
    setRating(event.target.value);
    setRatingError(""); // Clear rating error when a rating is selected
  };

  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value);
  };

  const handleSubmit = async () => {
    if (!rating) {
      setRatingError("Please select your rating.");
      return;
    }
    setIsLoading(true); // Start loading

    try {
      await axios.post("https://tmaasbackend.tdtlworld.com/api/feedback/", {
        rating,
        feedback,
      });

      await Swal.fire({
        title: "Feedback Submitted!",
        text: "Thank you for your feedback!",
        icon: "success",
        timer: 1000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
      setRating("");
      setFeedback("");
    } catch (error) {
      console.error(error);
      await Swal.fire({
        title: "Error",
        text: "An error occurred while submitting your feedback.",
        icon: "error",
        timer: 4000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } finally {
      // Stop the loading spinner
      setIsLoading(false);
    }
  };
  return (
    <>
      {/* <div
        style={{ display: "flex", flexDirection: "column", height: "100vh" }}
      > */}
      <DashNavbar />
      <div className="wrapper">
        <Sidebar />
        <div className="main">
          <div className="container mt-5">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-10 col-sm-12">
                <div
                  className="card"
                  style={{
                    overflow: "visible", // Override overflow
                    // maxWidth: "unset",   // Remove max-width
                    height: "auto", // Allow dynamic height
                    marginLeft: "0", // Reset margin
                  }}
                >
                  <div className="card-header bg-dark">
                    <h5
                      className="card-title text-white mt-2"
                      id="exampleModalLabel"
                    >
                      Feedback Request
                    </h5>
                  </div>
                  <div className="card-body">
                    <div className="text-center">
                      <i className="fa fa-4x mb-3 text-primary"></i>
                      <p className="mt-3">
                        <strong>Your opinion matters</strong>
                      </p>
                      <p>
                        Have some ideas how to improve our product?{" "}
                        <strong>Give us your feedback.</strong>
                      </p>
                    </div>

                    <hr />

                    <form>
                      <div className="row">
                        {/* Rating Section */}
                        <div className="col-md-4">
                          <p className="text-center">
                            <strong>Your rating: {rating}</strong>
                          </p>

                          {[
                            "Very Good",
                            "Good",
                            "Medium",
                            "Bad",
                            "Very Bad",
                          ].map((label, index) => (
                            <div className="form-check mb-2" key={index}>
                              <input
                                className="form-check-input"
                                type="radio"
                                name="rating"
                                id={`radio3Example${index}`}
                                value={label.replace(" ", "_")}
                                onChange={handleRatingChange}
                                required
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`radio3Example${index}`}
                              >
                                {label}
                              </label>
                            </div>
                          ))}
                          <div className="text-danger">{ratingError}</div>
                        </div>

                        {/* Feedback Section */}
                        <div className="col-md-8">
                          <p className="text-center">
                            <strong>What could we improve?</strong>
                          </p>
                          <div className="form-outline mb-4">
                            <textarea
                              className="form-control"
                              id="form4Example3"
                              rows="4"
                              onChange={handleFeedbackChange}
                            ></textarea>
                            <label
                              className="form-label"
                              htmlFor="form4Example3"
                            >
                              Your feedback
                            </label>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="card-footer text-center">
                    <button
                      type="button"
                      className="btn btn-outline-dark"
                      onClick={handleSubmit}
                      disabled={isLoading} // Disable button while loading
                    >
                      {isLoading ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        "  Submit"
                      )}

                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* </div> */}
    </>
  );
}
