
import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Sidebar from "./Sidebar";
import DashNavbar from "./DashNavbar";

export default function InterviewerForm() {
  const [formData, setFormData] = useState({
    name: "",
    employee_id: "",
    phone: "",
    email: "",
    skills: "",
    experience: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async (e) => {
    setIsLoading(true); // Start loading
    e.preventDefault();
    try {
      const response = await axios.post("https://tmaasbackend.tdtlworld.com/interviewer_details/",
        formData
      );
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Profile submitted successfully!",
        timer: 1000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      // Stop the loading spinner
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async (email) => {
      try {
        const response = await axios.get(`https://tmaasbackend.tdtlworld.com/interviewer_details/${email}/`);
        if (response.data) {
          setFormData(response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const storedName = localStorage.getItem("UserName");
    const storedEmail = localStorage.getItem("email");
    if (storedName && storedEmail) {
      setFormData({
        ...formData,
        name: storedName,
        email: storedEmail,
      });
      fetchData(storedEmail);
    }
  }, []);

  // Custom phone number validation
  const isPhoneValid = (phone) => {
    // Check if the phone number is exactly 10 digits and doesn't contain all zeros
    const regex = /^[0-9]{10}$/;
    return regex.test(phone) && phone !== "0000000000";
  };

  return (
    <>
      <DashNavbar />
      <div className="wrapper">
        <Sidebar />
        <div className="main pt-5">
          <div className="container mt-2">
            <div
              className="row justify-content-center "
              style={{ width: "100%" }}
            >
              <div className="col-md-8 col-9 auth-inner">
                <h2 className="display-6 gradient-text mb-4 ">
                  Interviewer Details
                </h2>
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <div className="row justify-content-center align-items-center">
                      <div className="col-md-6">
                        <label className="form-label text-end pe-3">
                          Name:
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          readOnly
                        />
                      </div>
                      <div className="col-md-6">
                        <label className="form-label text-end pe-3">
                          Employee ID:
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="employee_id"
                          placeholder="Enter Org Employee id"
                          value={formData.employee_id}
                          onChange={handleChange}
                          pattern="[0-9]*"
                          title="Please enter only numbers"
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mb-3">
                    <div className="row justify-content-center align-items-center">
                      <div className="col-md-6">
                        <label className="form-label text-end pe-3">
                          Phone:
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="phone"
                          placeholder="Enter 10 digit Phone No."
                          value={formData.phone}
                          onChange={handleChange}
                          pattern="[0-9]{10}"
                          title="Please enter a 10-digit phone number"
                          required
                        />
                        {formData.phone && !isPhoneValid(formData.phone) && (
                          <small className="text-danger">
                            Please enter a valid phone number.
                          </small>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label className="form-label text-end pe-3">
                          Email:
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mb-3">
                    <div className="row justify-content-center align-items-center">
                      <div className="col-md-6">
                        <label className="form-label text-end pe-3">
                          Skills:
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="skills"
                          placeholder="Enter comma separated values"
                          value={formData.skills}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="col-md-6">
                        <label className="form-label text-end pe-3">
                          Experience:
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="experience"
                          placeholder="Enter total years of experience"
                          value={formData.experience}
                          onChange={handleChange}
                          pattern="[0-9]{1,2}"
                          title="Please enter up to 2 digits"
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mb-3 pt-4">
                    <div className="row justify-content-center">
                      <div className="col-md-6">
                        <button
                          type="submit"
                          className="btn btn-primary w-100"
                          disabled={isLoading || !isPhoneValid(formData.phone)} // Disable button if phone is invalid
                        >
                          {isLoading ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            " Submit & Update"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import Swal from "sweetalert2";
// import Sidebar from "./Sidebar";
// import DashNavbar from "./DashNavbar";

// export default function InterviewerForm() {
//   const [formData, setFormData] = useState({
//     name: "",
//     employee_id: "",
//     phone: "",
//     email: "",
//     skills: "",
//     experience: "",
//   });

//   const handleChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

//   const [isLoading, setIsLoading] = useState(false);
//   const handleSubmit = async (e) => {
//     setIsLoading(true); // Start loading
//     e.preventDefault();
//     try {
//       const response = await axios.post("https://tmaasbackend.tdtlworld.com/interviewer_details/",
//         formData
//       );
//       Swal.fire({
//         icon: "success",
//         title: "Success!",
//         text: "Profile submitted successfully!",
//         timer: 1000,
//         timerProgressBar: true,
//         showConfirmButton: false,
//       });
//     } catch (error) {
//       console.error("Error submitting form:", error);
//     } finally {
//       // Stop the loading spinner
//       setIsLoading(false);
//     }
//   };

//   useEffect(() => {
//     const fetchData = async (email) => {
//       try {
//         const response = await axios.get(`https://tmaasbackend.tdtlworld.com/interviewer_details/${email}/`
//         );
//         if (response.data) {
//           setFormData(response.data);
//         }
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };
//     const storedName = localStorage.getItem("UserName");
//     const storedEmail = localStorage.getItem("email");
//     if (storedName && storedEmail) {
//       setFormData({
//         ...formData,
//         name: storedName,
//         email: storedEmail,
//       });
//       fetchData(storedEmail);
//     }
//   }, []);

//   return (
//     <>
//       {/* <div
//         style={{ display: "flex", flexDirection: "column", height: "100vh" }}
//       > */}
//       <DashNavbar />
//       <div className="wrapper">
//         <Sidebar />
//         <div className="main pt-5">
//           <div className="container mt-2">
//             <div
//               className="row justify-content-center "
//               style={{ width: "100%" }}
//             >
//               <div className="col-md-8 col-9 auth-inner">
//                 <h2 className="display-6 gradient-text mb-4 ">
//                   Interviewer Details
//                 </h2>
//                 <form onSubmit={handleSubmit}>
//                   <div className="mb-3">
//                     <div className="row justify-content-center align-items-center">
//                       <div className="col-md-6">
//                         <label className="form-label text-end pe-3">
//                           Name:
//                         </label>
//                         <input
//                           type="text"
//                           className="form-control"
//                           name="name"
//                           value={formData.name}
//                           onChange={handleChange}
//                           readOnly
//                         />
//                       </div>
//                       <div className="col-md-6">
//                         <label className="form-label text-end pe-3">
//                           Employee ID:
//                         </label>
//                         <input
//                           type="text"
//                           className="form-control"
//                           name="employee_id"
//                           placeholder="Enter Org Employee id"
//                           value={formData.employee_id}
//                           onChange={handleChange}
//                           pattern="[0-9]*"
//                           title="Please enter only numbers"
//                           required
//                         />
//                       </div>
//                     </div>
//                   </div>

//                   <div className="mb-3">
//                     <div className="row justify-content-center align-items-center">
//                       <div className="col-md-6">
//                         <label className="form-label text-end pe-3">
//                           Phone:
//                         </label>
//                         <input
//                           type="text"
//                           className="form-control"
//                           name="phone"
//                           placeholder="Enter 10 digit Phone No."
//                           value={formData.phone}
//                           onChange={handleChange}
//                           pattern="[0-9]{10}"
//                           title="Please enter a 10-digit phone number"
//                           required
//                         />
//                       </div>
//                       <div className="col-md-6">
//                         <label className="form-label text-end pe-3">
//                           Email:
//                         </label>
//                         <input
//                           type="text"
//                           className="form-control"
//                           name="email"
//                           value={formData.email}
//                           onChange={handleChange}
//                           readOnly
//                         />
//                       </div>
//                     </div>
//                   </div>

//                   <div className="mb-3">
//                     <div className="row justify-content-center align-items-center">
//                       <div className="col-md-6">
//                         <label className="form-label text-end pe-3">
//                           Skills:
//                         </label>
//                         <input
//                           type="text"
//                           className="form-control"
//                           name="skills"
//                           placeholder="Enter comma separated values"
//                           value={formData.skills}
//                           onChange={handleChange}
//                           required
//                         />
//                       </div>
//                       <div className="col-md-6">
//                         <label className="form-label text-end pe-3">
//                           Experience:
//                         </label>
//                         <input
//                           type="text"
//                           className="form-control"
//                           name="experience"
//                           placeholder="Enter total years of experience"
//                           value={formData.experience}
//                           onChange={handleChange}
//                           pattern="[0-9]{1,2}"
//                           title="Please enter up to 2 digits"
//                           required
//                         />
//                       </div>
//                     </div>
//                   </div>

//                   <div className="mb-3 pt-4">
//                     <div className="row justify-content-center">
//                       <div className="col-md-6">
//                         <button type="submit" className="btn btn-primary w-100"
//                           disabled={isLoading} // Disable button while loading
//                         >

//                           {isLoading ? (
//                             <span
//                               className="spinner-border spinner-border-sm"
//                               role="status"
//                               aria-hidden="true"
//                             ></span>
//                           ) : (
//                             " Submit & Update"
//                           )}

//                         </button>
//                       </div>
//                     </div>
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }
