import React, { useState } from "react";
import DashNavbar from "./DashNavbar";
import "bootstrap/dist/css/bootstrap.min.css";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import Sidebar from "./Sidebar";

export default function PDFViewer() {
  const [pdfFile, setPDFFile] = useState(null);
  const [viewPdf, setViewPdf] = useState(null);

  const handleChange = (e) => {
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile.type === "application/pdf") {
        let reader = new FileReader();
        reader.readAsDataURL(selectedFile);
        reader.onload = (e) => {
          setPDFFile(e.target.result); // Set the selected file for later viewing
        };
      } else {
        setPDFFile(null);
      }
    } else {
      console.log("Please select a file");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (pdfFile !== null) {
      setViewPdf(pdfFile); // Set the file to be viewed
    }
  };

  const handleClose = () => {
    setViewPdf(null); // Reset the PDF view, but don't clear the file
  };

  const newPlugin = defaultLayoutPlugin();

  return (
    <div className="d-flex flex-column min-vh-100">
      <DashNavbar />
      <div className="d-flex flex-grow-1">
        <Sidebar />
        <div className="container my-5">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-10 col-sm-12">
              <form onSubmit={handleSubmit} className="mb-4">
                <input
                  type="file"
                  className="form-control mb-3"
                  onChange={handleChange}
                />
                <button type="submit" className="btn btn-dark w-100 btn-sm">
                  View PDF
                </button>
              </form>
              <div className="pdf-viewer p-3 position-relative">
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                  {viewPdf ? (
                    <div>
                      <Viewer
                        fileUrl={viewPdf}
                        workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js"
                        plugins={[newPlugin]}
                      />
                      <button
                        onClick={handleClose}
                        className="btn btn-danger position-absolute top-0 end-0 m-2 btn-sm"
                        style={{ zIndex: 10 }} // Ensures the button stays on top
                      >
                        X
                      </button>
                    </div>
                  ) : (
                    !pdfFile && (
                      <div className="text-center text-muted">
                        No PDF selected
                      </div>
                    )
                  )}
                </Worker>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}





// import React, { useState } from "react";
// import DashNavbar from "./DashNavbar";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "@react-pdf-viewer/core/lib/styles/index.css";
// import "@react-pdf-viewer/default-layout/lib/styles/index.css";
// import { Worker, Viewer } from "@react-pdf-viewer/core";
// import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
// import Sidebar from "./Sidebar";

// export default function PDFViewer() {
//   const [pdfFile, setPDFFile] = useState(null);
//   const [viewPdf, setViewPdf] = useState(null);

//   const handleChange = (e) => {
//     let selectedFile = e.target.files[0];
//     if (selectedFile) {
//       if (selectedFile.type === "application/pdf") {
//         let reader = new FileReader();
//         reader.readAsDataURL(selectedFile);
//         reader.onload = (e) => {
//           setPDFFile(e.target.result);
//         };
//       } else {
//         setPDFFile(null);
//       }
//     } else {
//       console.log("Please select a file");
//     }
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     if (pdfFile !== null) {
//       setViewPdf(pdfFile);
//     } else {
//       setViewPdf(null);
//     }
//   };

//   const newPlugin = defaultLayoutPlugin();

//   return (
//     <div className="d-flex flex-column min-vh-100">
//       <DashNavbar />
//       <div className="d-flex flex-grow-1">
//         <Sidebar />
//         <div className="container my-5">
//           <div className="row justify-content-center">
//             <div className="col-lg-6 col-md-10 col-sm-12">
//               <form onSubmit={handleSubmit} className="mb-4">
//                 <input
//                   type="file"
//                   className="form-control mb-3"
//                   onChange={handleChange}
//                 />
//                 <button type="submit" className="btn btn-dark w-100">
//                   View PDF
//                 </button>
//               </form>
//               <div className="pdf-viewer p-3">
//                 <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
//                   {viewPdf ? (
//                     <Viewer
//                       fileUrl={viewPdf}
//                       workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js"
//                       plugins={[newPlugin]}
//                     />
//                   ) : (
//                     !pdfFile && (
//                       <div className="text-center text-muted">
//                         No PDF selected
//                       </div>
//                     )
//                   )}
//                 </Worker>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
