// import React, { useState } from "react";
// import Navbar from "../Misc/Navbar";
// import axios from "axios";
// import Swal from "sweetalert2";
// import { useNavigate } from "react-router-dom";
// import bcrypt from "bcryptjs";
// import { Link } from "react-router-dom";
// import { TextField } from "@mui/material";
// import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons for password visibility toggle
// import "../Authentication/signup.component.css";

// export default function SignUp() {
//   const navigate = useNavigate();

//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//     password: "",
//   });

//   const [errors, setErrors] = useState({
//     password: "",
//   });

//   const [showPassword, setShowPassword] = useState(false); // State to handle password visibility

//   const handleInputChange = (event) => {
//     const { name, value } = event.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleInputChange_name = (event) => {
//     const { name, value } = event.target;
//     const regex = /^[a-zA-Z]*$/;
//     if (regex.test(value)) {
//       setFormData({
//         ...formData,
//         [name]: value,
//       });
//     }
//   };

//   const validatePassword = (password) => {
//     const passwordRegex =
//       /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
//     return passwordRegex.test(password);
//   };

//   const handleLogin = async (event) => {
//     event.preventDefault();

//     if (!validatePassword(formData.password)) {
//       setErrors({
//         password:
//           "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character.",
//       });
//       return;
//     }

//     const hashedPassword = await bcrypt.hash(formData.password, 10);

//     const loginData = {
//       name: formData.name,
//       email: formData.email,
//       password: hashedPassword,
//     };

//     axios
//       .post("https://tmaasbackend.tdtlworld.com/Signup_view/", loginData)
//       .then((response) => {
//         console.log("SignUp Successful:", response.data);

//         Swal.fire({
//           icon: "success",
//           title: "SignUp Successful!",
//           text: "You can perform additional actions after successful signup.",
//           timer: 1000,
//           timerProgressBar: true,
//           showConfirmButton: false,
//         });

//         navigate("/sign-in");
//       })
//       .catch((error) => {
//         console.error("SignUp Error:", error);

//         Swal.fire({
//           icon: "error",
//           title: "SignUp Error",
//           text: "There was an error during signup. Please try again.",
//           timer: 4000,
//           timerProgressBar: true,
//           showConfirmButton: false,
//         });
//       });
//   };

//   return (
//     <div className="signups-container">
//       <Navbar />
//       <div className="signups-content">
//         <div className="signups-box">
//           <form onSubmit={handleLogin}>
//             <h2>Sign Up</h2>
//             <div className="mb-3 mt-5">
//               <TextField
//                 fullWidth
//                 color="secondary"
//                 type="name"
//                 required
//                 name="name"
//                 value={formData.name}
//                 label="Name"
//                 onChange={handleInputChange_name}
//               />
//             </div>
//             <div className="mb-3">
//               <TextField
//                 fullWidth
//                 color="secondary"
//                 type="email"
//                 required
//                 name="email"
//                 value={formData.email}
//                 label="Email"
//                 onChange={handleInputChange}
//               />
//             </div>
//             <div className="mb-3 position-relative">
//               <TextField
//                 fullWidth
//                 type={showPassword ? "text" : "password"} // Toggle between text and password
//                 required
//                 name="password"
//                 color="secondary"
//                 value={formData.password}
//                 label="Password"
//                 onChange={handleInputChange}
//               />
//               {/* Eye icon to toggle password visibility */}
//               <div
//                 className="eye-icon"
//                 onClick={() => setShowPassword(!showPassword)} // Toggle visibility on click
//                 style={{
//                   cursor: "pointer",
//                   position: "absolute",
//                   right: "30px", // Adjust the positioning to fit in the input field
//                   top: "10px",
//                   fontSize: "25px", // Adjust icon size if needed
//                 }}
//               >
//                 {showPassword ? <FaEyeSlash /> : <FaEye />} {/* Show or hide icon */}
//               </div>
//               {errors.password && (
//                 <div className="error-text">{errors.password}</div>
//               )}
//             </div>
//             <div className="d-grid">
//               <button type="submit" className="btn-shiny2" style={{ margin: "auto auto" }}>
//                 Create Account
//               </button>
//             </div>
//             <p className="forgot-password text-center">
//               Already registered <Link to="/sign-in">sign-in?</Link>
//             </p>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// }


import React, { useState } from "react";
import Navbar from "../Misc/Navbar";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import bcrypt from "bcryptjs";
import { Link } from "react-router-dom";
import { TextField } from "@mui/material";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "../Authentication/signup.component.css";

export default function SignUp() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false); // New state for loading

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleInputChange_name = (event) => {
    const { name, value } = event.target;
    const regex = /^[a-zA-Z\s_]*$/; // Allow letters, spaces, and underscores
    if (regex.test(value)) {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  
  

  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  };

  const handleLogin = async (event) => {
    event.preventDefault();

    if (!validatePassword(formData.password)) {
      setErrors({
        password:
          "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character.",
      });
      return;
    }

    setLoading(true); // Set loading to true

    const hashedPassword = await bcrypt.hash(formData.password, 10);

    const loginData = {
      name: formData.name,
      email: formData.email,
      password: hashedPassword,
    };

    axios
      .post("https://tmaasbackend.tdtlworld.com/Signup_view/", loginData)
      .then((response) => {
        console.log("SignUp Successful:", response.data);

        Swal.fire({
          icon: "success",
          title: "SignUp Successful!",
          text: "You can perform additional actions after successful signup.",
          timer: 1000,
          timerProgressBar: true,
          showConfirmButton: false,
        });

        navigate("/sign-in");
      })
      .catch((error) => {
        console.error("SignUp Error:", error);

        Swal.fire({
          icon: "error",
          title: "SignUp Error",
          text: "There was an error during signup. Please try again.",
          timer: 4000,
          timerProgressBar: true,
          showConfirmButton: false,
        });
      })
      .finally(() => {
        setLoading(false); // Reset loading state
      });
  };

  return (
    <div className="signups-container">
      <Navbar />
      <div className="signups-content">
        <div className="signups-box">
          <form onSubmit={handleLogin}>
            <h2>Sign Up</h2>
            <div className="mb-3 mt-5">
              <TextField
                fullWidth
                color="secondary"
                type="name"
                required
                name="name"
                value={formData.name}
                label="Name"
                onChange={handleInputChange_name}
              />
            </div>
            <div className="mb-3">
              <TextField
                fullWidth
                color="secondary"
                type="email"
                required
                name="email"
                value={formData.email}
                label="Email"
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-3 position-relative">
              <TextField
                fullWidth
                type={showPassword ? "text" : "password"}
                required
                name="password"
                color="secondary"
                value={formData.password}
                label="Password"
                onChange={handleInputChange}
              />
              <div
                className="eye-icon"
                onClick={() => setShowPassword(!showPassword)}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  right: "30px",
                  top: "10px",
                  fontSize: "25px",
                }}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </div>
              {errors.password && (
                <div className="error-text">{errors.password}</div>
              )}
            </div>
            <div className="d-grid">
              <button
                type="submit"
                className="btn-shiny2"
                style={{ margin: "auto auto", position: "relative" }}
                disabled={loading} // Disable the button while loading
              >
                {loading ? (
                  <span className="spinner-border spinner-border-sm"></span>
                ) : (
                  "Create Account"
                )}
              </button>
            </div>
            <p className="forgot-password text-center">
              Already registered <Link to="/sign-in">sign-in?</Link>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
}
