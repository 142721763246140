import { Link } from "react-router-dom";
import brandlogo from "../../Assets/tdtl_logo.png";
export default function Navbar() {
  return (
    <>
      <nav className="navbar navbar-expand navbar-light ">
        <div className="container">
          <Link className="navbar-brand" to={"/"}>
            <img
              src={brandlogo}
              alt="Logo"
              style={{ height: "1.7em", width: "8em" }}
            />
          </Link>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
            <ul className="navbar-nav ms-left">
              <li className="nav-item">
                <Link className="nav-link" to={"/sign-in"}>
                  Login
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={"/sign-up"}>
                  Register
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
