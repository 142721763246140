// _________________________________________________NOTIFICATION______________________________________________
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import AuthService from "../Authentication/authService";
import { useNavigate } from "react-router-dom";
import { FaUser, FaCog, FaSignOutAlt, FaBell } from "react-icons/fa";
import "../css/index.css";
import ProfileModal from "./ProfileModal";
import brandlogo from "../../Assets/tdtl_logo.png";
import { Popover, Badge, OverlayTrigger } from "react-bootstrap";
import userprofile from "../../Assets/userprofile.png";
import axios from "axios";

export default function DashNavbar(props) {
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);
  const [userName, setUserName] = useState("");
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [userData, setUserData] = useState(null);
  const [profilePictureBlobURL, setProfilePictureBlobURL] = useState(null);
  const [notificationCount, setNotificationCount] = useState(0);

  const hasFetchedProfilePicture = useRef(false);

  useEffect(() => {
    const fetchData = async () => {
      const email = localStorage.getItem("email");
      if (email) {
        try {
          const response = await axios.post("https://tmaasbackend.tdtlworld.com/UserProfile/", { email });
          setUserData(response.data.user_data);
        } catch (error) {
          console.error("Error fetching data:", error.message);
        }
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (userData && !profilePictureBlobURL && !hasFetchedProfilePicture.current) {
      const fetchProfilePicture = async () => {
        try {
          const response = await axios.get(`https://tmaasbackend.tdtlworld.com/profile-picture/${userData.id}/`, { responseType: "blob" });
          const blobURL = URL.createObjectURL(response.data);
          setProfilePictureBlobURL(blobURL);
          hasFetchedProfilePicture.current = true;
        } catch (error) {
          console.error("Error fetching profile picture:", error.message);
        }
      };

      fetchProfilePicture();
    }
  }, [userData]);

  useEffect(() => {
    const storedUserName = localStorage.getItem("UserName");
    if (storedUserName) {
      setUserName(storedUserName);
      setShowDropdown(true);
    }
    const storedRole = localStorage.getItem("User_Role");
    if (storedRole) {
      fetchNotifications(storedRole);
    }
  }, []);

  const fetchNotifications = async (storedRole) => {
    const userId = localStorage.getItem("user_id");
    try {
      const response = await axios.get(`https://tmaasbackend.tdtlworld.com/get_notifications/?role=${storedRole}&user_id=${userId}`
      );
      const notifications = response.data.notifications.map(notification => ({
        ...notification,
        seen: notification.seen_by.includes(userId)
      }));
      setNotifications(notifications);
      const unseenCount = notifications.filter(notification => !notification.seen).length;
      setNotificationCount(unseenCount);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  const handleProfileClick = () => {
    setShowProfileModal(true);
  };

  const handleLogout = () => {
    AuthService.logout()
      .then(() => {
        navigate("/sign-in");
        localStorage.clear();
      })
      .catch((error) => {
        console.error("Logout Error:", error);
      });
  };
  const handleBellClick = async () => {
    const storedRole = localStorage.getItem("User_Role");
    const userId = localStorage.getItem("user_id");
    try {
      const response = await axios.get(`https://tmaasbackend.tdtlworld.com/get_notifications/?role=${storedRole}&user_id=${userId}`
      );
      const notifications = response.data.notifications.map(notification => ({
        ...notification,
        seen: notification.seen_by.includes(userId)
      }));
      setNotifications(notifications);

      const unseenCount = notifications.filter(notification => !notification.seen).length;
      setNotificationCount(unseenCount);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };


  const getTimeDifference = (notificationTime) => {
    const currentTime = new Date();
    const notificationDate = new Date(notificationTime);
    const timeDifference = currentTime.getTime() - notificationDate.getTime();
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return `${days} day${days > 1 ? "s" : ""} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else {
      return `${seconds} second${seconds > 1 ? "s" : ""} ago`;
    }
  };

  const handleNotificationClick = async (notificationId) => {
    const userId = localStorage.getItem("user_id");
    try {
      // Mark the notification as seen
      await axios.post("https://tmaasbackend.tdtlworld.com/mark_notification_as_seen/", {
        notification_id: notificationId,
        user_id: userId,
      });

      // Fetch updated notifications and count
      const storedRole = localStorage.getItem("User_Role");
      const response = await axios.get(`https://tmaasbackend.tdtlworld.com/get_notifications/?role=${storedRole}&user_id=${userId}`
      );
      const notifications = response.data.notifications.map(notification => ({
        ...notification,
        seen: notification.seen_by.includes(userId)
      }));
      setNotifications(notifications);

      const unseenCount = notifications.filter(notification => !notification.seen).length;
      setNotificationCount(unseenCount);
    } catch (error) {
      console.error("Error marking notification as seen:", error);
    }
  };

  const popover = () => {
    const sortedNotifications = [...notifications].sort((a, b) => {
      // Sort by 'seen' status, unseen notifications first
      if (a.seen && !b.seen) return 1;
      if (!a.seen && b.seen) return -1;
      // If both seen/unseen, sort by time (latest first)
      return new Date(b.time) - new Date(a.time);
    });

    return (
      <Popover id="popover-notifications">
        <Popover.Header as="h3">Notifications</Popover.Header>
        <Popover.Body style={{ maxHeight: "50vh", overflowY: "auto" }}>
          <div className="notification-list">
            {sortedNotifications.map((notification, index) => (
              <div
                key={index}
                className={`notification-item ${notification.seen ? 'seen' : 'unseen'}`}
                onClick={() => handleNotificationClick(notification.id)}
              >
                <p className="notification-msg">{notification.message}</p>
                <p className="notification-time">
                  {getTimeDifference(notification.time)}
                </p>
              </div>
            ))}
          </div>
        </Popover.Body>
      </Popover>
    );
  };

  return (
    <>
      <nav className="navbar navbar-expand navbar-dark gradient-navbar">
        <div className="container custom-container-width d-flex align-items-center">
          {/* Brand Logo */}
          <Link className="navbar-brand" to={"/Admindashboard"}>
            <img
              src={brandlogo}
              alt="Logo"
              style={{ height: "1.7em", width: "8em" }}
            />
          </Link>

          {/* Bell Icon and User Dropdown */}
          <div className="d-flex align-items-center">
            {/* Bell Icon */}
            <OverlayTrigger
              trigger="click"
              placement="bottom"
              overlay={popover()}
            >
              <div className="bell-icon mr-3" onClick={handleBellClick}>
                <FaBell
                  style={{
                    fontSize: "1.5em",
                    color: "white",
                    marginRight: "0.7em",
                  }}
                />
                {notificationCount > 0 && (
                  <Badge bg="danger" className="bell-badge">
                    {notificationCount}
                  </Badge>
                )}
              </div>
            </OverlayTrigger>
            {/* User Dropdown */}
            {showDropdown && (
              <div className="user-dropdown">
                <div className="d-flex align-items-center">
                  <span className="user-name-container">
                    {userName && (
                      <span className="user-name text-light me-2">
                        {userName}
                      </span>
                    )}
                  </span>
                  <div
                    className="nav-profile-picture-container mx-2 position-relative"
                    onClick={handleProfileClick}
                  >
                    <img
                      src={
                        profilePictureBlobURL ||
                        userData?.profile_picture ||
                        userprofile
                      }
                      alt="Profile"
                      className="nav-profile-picture"
                    />
                  </div>

                  <button
                    className="px-2 border-0 dropdown-toggle"
                    style={{
                      backgroundColor: "transparent",
                      color: "white",
                    }}
                    type="button"
                    id="userDropdown"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  ></button>
                  <ul
                    className="dropdown-menu dropdown-menu-end me-3"
                    aria-labelledby="userDropdown"
                  >
                    <li>
                      <button
                        className="dropdown-item for-hover"
                        onClick={handleProfileClick}
                      >
                        <FaUser className="me-2" />
                        Profile
                      </button>
                    </li>
                    <li>
                      {/* <Link
                        className="dropdown-item for-hover"
                        to={"/settings"}
                      >
                        <FaCog className="me-2" />
                        Settings
                      </Link> */}
                    </li>
                    <li>
                      <Link
                        className="dropdown-item for-hover"
                        onClick={handleLogout}
                      >
                        <FaSignOutAlt className="me-2" />
                        Log out
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </nav>

      {/* Profile Modal */}
      <ProfileModal
        show={showProfileModal}
        onClose={() => setShowProfileModal(false)}
        userName={userName}
        userData={userData}
      />
    </>
  );
}

// __________________________________SESSSION TIMEOUT______________________________________
// import React, { useState, useEffect, useRef } from "react";
// import { Link } from "react-router-dom";
// import AuthService from "../Authentication/authService";
// import { useNavigate } from "react-router-dom";
// import { FaUser, FaCog, FaSignOutAlt, FaBell } from "react-icons/fa";
// import "../css/index.css";
// import ProfileModal from "./ProfileModal";
// import brandlogo from "../../Assets/tdtl_logo.png";
// import { Popover, Badge, OverlayTrigger, Modal, Button } from "react-bootstrap";
// import userprofile from "../../Assets/userprofile.png";
// import axios from "axios";

// export default function DashNavbar(props) {
//   const navigate = useNavigate();
//   const [showDropdown, setShowDropdown] = useState(false);
//   const [userName, setUserName] = useState("");
//   const [showProfileModal, setShowProfileModal] = useState(false);
//   const [notifications, setNotifications] = useState([]);
//   const [userData, setUserData] = useState(null);
//   const [profilePictureBlobURL, setProfilePictureBlobURL] = useState(null);
//   const [notificationCount, setNotificationCount] = useState(0);
//   const [sessionTimeoutWarning, setSessionTimeoutWarning] = useState(false);

//   const hasFetchedProfilePicture = useRef(false);
//   const sessionTimeoutRef = useRef(null);
//   const sessionWarningTimeoutRef = useRef(null);

//   const SESSION_TIMEOUT = 2* 60 * 1000; // 30 minutes
//   const WARNING_TIMEOUT =  0.5* 60 * 1000; // 25 minutes (5 minutes before timeout)

//   useEffect(() => {
//     const fetchData = async () => {
//       const email = localStorage.getItem("email");
//       if (email) {
//         try {
//           const response = await axios.post(
//             "https://tmaasbackend.tdtlworld.com/UserProfile/",
//             { email }
//           );
//           setUserData(response.data.user_data);
//         } catch (error) {
//           console.error("Error fetching data:", error.message);
//         }
//       }
//     };

//     fetchData();
//     startSessionTimeout();

//     return () => {
//       clearTimeout(sessionTimeoutRef.current);
//       clearTimeout(sessionWarningTimeoutRef.current);
//     };
//   }, []);

//   useEffect(() => {
//     if (userData && !profilePictureBlobURL && !hasFetchedProfilePicture.current) {
//       const fetchProfilePicture = async () => {
//         try {
//           const response = await axios.get(
//             `https://tmaasbackend.tdtlworld.com/profile-picture/${userData.id}/`,
//             { responseType: "blob" }
//           );
//           const blobURL = URL.createObjectURL(response.data);
//           setProfilePictureBlobURL(blobURL);
//           hasFetchedProfilePicture.current = true;
//         } catch (error) {
//           console.error("Error fetching profile picture:", error.message);
//         }
//       };

//       fetchProfilePicture();
//     }
//   }, [userData]);

//   useEffect(() => {
//     const storedUserName = localStorage.getItem("UserName");
//     if (storedUserName) {
//       setUserName(storedUserName);
//       setShowDropdown(true);
//     }
//     const storedRole = localStorage.getItem("User_Role");
//     if (storedRole) {
//       fetchNotifications(storedRole);
//     }
//   }, []);

//   const startSessionTimeout = () => {
//     clearTimeout(sessionTimeoutRef.current);
//     clearTimeout(sessionWarningTimeoutRef.current);

//     sessionWarningTimeoutRef.current = setTimeout(() => {
//       setSessionTimeoutWarning(true);
//     }, WARNING_TIMEOUT);

//     sessionTimeoutRef.current = setTimeout(() => {
//       handleLogout();
//     }, SESSION_TIMEOUT);
//   };

//   const fetchNotifications = async (storedRole) => {
//     try {
//       const response = await axios.get(
//         `https://tmaasbackend.tdtlworld.com/get_notifications/?role=${storedRole}`
//       );
//       setNotifications(response.data.notifications);
//       setNotificationCount(response.data.notifications.length);
//     } catch (error) {
//       console.error("Error fetching notifications:", error);
//     }
//   };

//   const handleProfileClick = () => {
//     setShowProfileModal(true);
//   };

//   const handleLogout = () => {
//     AuthService.logout()
//       .then(() => {
//         navigate("/sign-in");
//         localStorage.clear();
//       })
//       .catch((error) => {
//         console.error("Logout Error:", error);
//       });
//   };

//   const handleBellClick = async () => {
//     await fetchNotifications(localStorage.getItem("User_Role"));
//     setNotificationCount(0);
//   };

//   const handleSessionTimeoutClose = () => {
//     setSessionTimeoutWarning(false);
//     // startSessionTimeout(); // Reset the session timeout
//   };

//   const getTimeDifference = (notificationTime) => {
//     const currentTime = new Date();
//     const notificationDate = new Date(notificationTime);
//     const timeDifference = currentTime.getTime() - notificationDate.getTime();
//     const seconds = Math.floor(timeDifference / 1000);
//     const minutes = Math.floor(seconds / 60);
//     const hours = Math.floor(minutes / 60);
//     const days = Math.floor(hours / 24);

//     if (days > 0) {
//       return `${days} day${days > 1 ? "s" : ""} ago`;
//     } else if (hours > 0) {
//       return `${hours} hour${hours > 1 ? "s" : ""} ago`;
//     } else if (minutes > 0) {
//       return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
//     } else {
//       return `${seconds} second${seconds > 1 ? "s" : ""} ago`;
//     }
//   };

//   const popover = () => (
//     <Popover id="popover-notifications">
//       <Popover.Header as="h3">Notifications</Popover.Header>
//       <Popover.Body style={{ maxHeight: "50vh", overflowY: "auto" }}>
//         <div className="notification-list">
//           {notifications.map((notification, index) => (
//             <div key={index} className="notification-item">
//               <p className="notification-msg">{notification.message}</p>
//               <p className="notification-time">
//                 {getTimeDifference(notification.time)}
//               </p>
//             </div>
//           ))}
//         </div>
//       </Popover.Body>
//     </Popover>
//   );

//   return (
//     <>
//       <nav className="navbar navbar-expand navbar-dark gradient-navbar">
//         <div className="container custom-container-width d-flex align-items-center">
//           {/* Brand Logo */}
//           <Link className="navbar-brand" to={"/Admindashboard"}>
//             <img
//               src={brandlogo}
//               alt="Logo"
//               style={{ height: "1.7em", width: "8em" }}
//             />
//           </Link>

//           {/* Bell Icon and User Dropdown */}
//           <div className="d-flex align-items-center">
//             {/* Bell Icon */}
//             <OverlayTrigger
//               trigger="click"
//               placement="bottom"
//               overlay={popover()}
//             >
//               <div className="bell-icon mr-3" onClick={handleBellClick}>
//                 <FaBell
//                   style={{
//                     fontSize: "1.5em",
//                     color: "white",
//                     marginRight: "0.7em",
//                   }}
//                 />
//                 {notificationCount > 0 && (
//                   <Badge bg="danger" className="bell-badge">
//                     {notificationCount}
//                   </Badge>
//                 )}
//               </div>
//             </OverlayTrigger>
//             {/* User Dropdown */}
//             {showDropdown && (
//               <div className="user-dropdown">
//                 <div className="d-flex align-items-center">
//                   <span className="user-name-container">
//                     {userName && (
//                       <span className="user-name text-light me-2">
//                         {userName}
//                       </span>
//                     )}
//                   </span>
//                   <div
//                     className="nav-profile-picture-container mx-2 position-relative"
//                     onClick={handleProfileClick}
//                   >
//                     <img
//                       src={
//                         profilePictureBlobURL ||
//                         userData?.profile_picture ||
//                         userprofile
//                       }
//                       alt="Profile"
//                       className="nav-profile-picture"
//                     />
//                   </div>

//                   <button
//                     className="px-2 border-0 dropdown-toggle"
//                     style={{
//                       backgroundColor: "transparent",
//                       color: "white",
//                     }}
//                     type="button"
//                     id="userDropdown"
//                     data-bs-toggle="dropdown"
//                     aria-expanded="false"
//                   ></button>
//                   <ul
//                     className="dropdown-menu dropdown-menu-end me-3"
//                     aria-labelledby="userDropdown"
//                   >
//                     <li>
//                       <button
//                         className="dropdown-item for-hover"
//                         onClick={handleProfileClick}
//                       >
//                         <FaUser className="me-2" />
//                         Profile
//                       </button>
//                     </li>
//                     <li>
//                       <Link
//                         className="dropdown-item for-hover"
//                         to={"/settings"}
//                       >
//                         <FaCog className="me-2" />
//                         Settings
//                       </Link>
//                     </li>
//                     <li>
//                       <Link
//                         className="dropdown-item for-hover"
//                         onClick={handleLogout}
//                       >
//                         <FaSignOutAlt className="me-2" />
//                         Log out
//                       </Link>
//                     </li>
//                   </ul>
//                 </div>
//               </div>
//             )}
//           </div>
//         </div>
//       </nav>

//       {/* Profile Modal */}
//       <ProfileModal
//         show={showProfileModal}
//         onClose={() => setShowProfileModal(false)}
//         userName={userName}
//         userData={userData}
//       />

//       {/* Session Timeout Warning Modal */}
//       <Modal show={sessionTimeoutWarning} onHide={handleSessionTimeoutClose}>
//         <Modal.Header closeButton>
//           <Modal.Title>Session Timeout Warning</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           Your session will expired with in 1 minutes. Do you want to extend your session?
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleLogout}>
//             Logout
//           </Button>
//           {/* <Button variant="primary" onClick={handleSessionTimeoutClose}>
//             Extend Session
//           </Button> */}
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// }
