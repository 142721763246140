import React, { useState, useEffect } from "react";
import DashNavbar from "./DashNavbar";
import Sidebar from "./Sidebar";
import ApplicantDetailsModal from "../ProfileForm/ApplicantDetailsModal";
import "../css/dash.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/PDFViewer.css";
import { Link } from "react-router-dom";
import { Pagination } from "react-bootstrap";
import { Spinner } from "react-bootstrap"; // <-- Added Spinner from react-bootstrap

function InterviewerDashboard() {
  const [interviewSchedule, setInterviewSchedule] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedApplicant, setSelectedApplicant] = useState(null);
  const [appliedJobs, setAppliedJobs] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [searchQuery, setSearchQuery] = useState("");
  const [loadingTable, setLoadingTable] = useState(true); // <-- New state for table loader
  const [loadingView, setLoadingView] = useState(""); // <-- New state for View button loader
  const [loadingResume, setLoadingResume] = useState({});

  useEffect(() => {
    fetchInterviewSchedule();
  }, []);

  const fetchInterviewSchedule = () => {
    const userEmail = localStorage.getItem("email");
    if (!userEmail) {
      console.error("User email not found in local storage");
      return;
    }
    setLoadingTable(true); // <-- Enable loader before data fetch

    fetch(`https://tmaasbackend.tdtlworld.com/interviewers/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: userEmail }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const formattedData = data.map((interview) => ({
          ...interview,
          date: formatDate(interview.date),
          time: formatTime(interview.time),
        }));
        setInterviewSchedule(formattedData);

        // Fetch applied jobs for the interview schedule
        fetchAppliedJobsForSchedule(formattedData);
      })
      .catch((error) => {
        console.error("Error fetching interview schedule:", error);
      })
      .finally(() => setLoadingTable(false)); // <-- Disable loader after data fetch
  };

  const fetchAppliedJobsForSchedule = (schedule) => {
    const candidateEmails = schedule.map(
      (interview) => interview.candidate_email
    );
    candidateEmails.forEach((email) => {
      fetch(`https://tmaasbackend.tdtlworld.com/get_applied_job/${email}/`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((appliedJobsResponse) => {
          setAppliedJobs((prevJobs) => ({
            ...prevJobs,
            [email]: appliedJobsResponse.job_titles || ["Not Applied"],
          }));
        })
        .catch((error) => {
          console.error("Error fetching applied jobs:", error);
        });
    });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = `${date.getDate()}-${
      date.getMonth() + 1
    }-${date.getFullYear()}`;
    return formattedDate;
  };

  const formatTime = (timeString) => {
    const time = new Date(`01/01/2022 ${timeString}`);
    const formattedTime = time.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
    });
    return formattedTime;
  };

  const handleViewClick = (candidateEmail) => {
    setLoadingView((prev) => ({ ...prev, [candidateEmail]: true })); // Start loader for specific button
    fetch("https://tmaasbackend.tdtlworld.com/get_applicant_data/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: candidateEmail }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setSelectedApplicant(data);
        setShowModal(true);
      })
      .catch((error) => {
        console.error("Error fetching applicant data:", error);
      })
      .finally(() => {
        setLoadingView((prev) => ({ ...prev, [candidateEmail]: false })); // Stop loader for specific button
      });
  };

  const handleViewResume = (candidateEmail) => {
    setLoadingResume((prev) => ({ ...prev, [candidateEmail]: true })); // Start loader for specific button
    fetch("https://tmaasbackend.tdtlworld.com/view_resume/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: candidateEmail }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        const resumeBlobUrl = URL.createObjectURL(blob);
        window.open(resumeBlobUrl, "_blank");
      })
      .catch((error) => {
        console.error("Error fetching resume:", error);
      })
      .finally(() => {
        setLoadingResume((prev) => ({ ...prev, [candidateEmail]: false })); // Stop loader for specific button
      });
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = interviewSchedule.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(interviewSchedule.length / itemsPerPage);
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredSchedule = interviewSchedule.filter((interview) => {
    const candidateMatch = interview.candidate
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    const dateMatch = interview.date
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    const appliedJobMatch = appliedJobs[interview.candidate_email]?.some(
      (job) => job.toLowerCase().includes(searchQuery.toLowerCase())
    );
    return candidateMatch || dateMatch || appliedJobMatch;
  });

  return (
    <>
      {/* <div
        style={{ display: "flex", flexDirection: "column", height: "100vh" }}
      > */}
      <DashNavbar />
      <div className="wrapper">
        <Sidebar />
        <div className="col-lg-10 col-md-8 col-sm-12 main dash4">
          <div className="container mt-4">
            <div className="text-start">
              <h3>Welcome to your Dashboard</h3>
              <p>Below is your interview schedule:</p>
            </div>
            <div
              className="auth-inner mt-5 w-100"
              style={{ padding: "10px 10px 10px 10px" }}
            >
              <h2 className="mb-4">Interview Schedule</h2>
              <div className="input-group justify-content-center align-items-center">
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-lg-6 mx-auto">
                      <div className="input-group">
                        <input
                          type="search"
                          className="form-control"
                          placeholder="Search by Name or Email"
                          onChange={handleSearch}
                        />
                        <button className="btn btn-primary" type="button">
                          <i className="fas fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Sr.No</th>
                      <th scope="col">Candidate</th>
                      <th scope="col">Date</th>
                      <th scope="col">Time</th>
                      <th scope="col">Applied Job</th>
                      <th scope="col">Meeting Link</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  {loadingTable ? ( // <-- Conditional rendering for loader
                    <tbody>
                      <tr>
                        <td colSpan="7" className="text-center">
                          <Spinner animation="border" /> {/* <-- Spinner */}
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      {filteredSchedule
                        .slice(indexOfFirstItem, indexOfLastItem)
                        .map((interview, index) => (
                          <tr key={index}>
                            <td>{indexOfFirstItem + index + 1}</td>
                            <td>{interview.candidate}</td>
                            <td>{interview.date}</td>
                            <td>{interview.time}</td>
                            <td className="w-25">
                              {appliedJobs[interview.candidate_email]?.join(
                                ", "
                              )}
                            </td>
                            <td>
                              <Link
                                to={interview.meetLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn btn-primary  mb-2 mb-sm-0 me-sm-2"
                              >
                                Join Meeting
                              </Link>
                            </td>
                            <td>
                              <div className="d-flex flex-column flex-sm-row">
                                <button
                                  className="btn btn-secondary mb-2 mb-sm-0 me-sm-2"
                                  onClick={() =>
                                    handleViewClick(interview.candidate_email)
                                  }
                                  disabled={
                                    loadingView[interview.candidate_email]
                                  }
                                >
                                  {loadingView[interview.candidate_email] ? (
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                  ) : (
                                    "View"
                                  )}
                                </button>
                                <button
                                  className="btn btn-secondary"
                                  onClick={() =>
                                    handleViewResume(interview.candidate_email)
                                  }
                                  disabled={
                                    loadingResume[interview.candidate_email]
                                  }
                                >
                                  {loadingResume[interview.candidate_email] ? (
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                  ) : (
                                    "Resume"
                                  )}
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  )}
                </table>
              </div>
              <Pagination className="mt-4">
                {/* <Pagination.First onClick={() => paginate(1)} disabled={currentPage === 1} /> */}
                <Pagination.Prev
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                />
                {Array.from({ length: totalPages }, (_, i) => (
                  <Pagination.Item
                    key={i + 1}
                    active={i + 1 === currentPage}
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() => paginate(currentPage + 1)}
                  disabled={currentPage === totalPages}
                />
                {/* <Pagination.Last onClick={() => paginate(totalPages)} disabled={currentPage === totalPages} /> */}
              </Pagination>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}

      <ApplicantDetailsModal
        applicant={selectedApplicant || {}}
        show={showModal}
        onHide={() => setShowModal(false)}
      />
    </>
  );
}

export default InterviewerDashboard;

// import React, { useState, useEffect } from "react";
// import DashNavbar from "./DashNavbar";
// import Sidebar from "./Sidebar";
// import ApplicantDetailsModal from "../ProfileForm/ApplicantDetailsModal";
// import "../css/dash.css";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "../css/PDFViewer.css";
// import { Link } from "react-router-dom";
// import { Pagination } from "react-bootstrap";
// import { Spinner } from "react-bootstrap"; // <-- Added Spinner from react-bootstrap

// function InterviewerDashboard() {
//   const [interviewSchedule, setInterviewSchedule] = useState([]);
//   const [showModal, setShowModal] = useState(false);
//   const [selectedApplicant, setSelectedApplicant] = useState(null);
//   const [appliedJobs, setAppliedJobs] = useState({});
//   const [currentPage, setCurrentPage] = useState(1);
//   const itemsPerPage = 5;
//   const [searchQuery, setSearchQuery] = useState("");
//   const [loadingTable, setLoadingTable] = useState(true); // <-- New state for table loader
//   const [loadingView, setLoadingView] = useState(""); // <-- New state for View button loader
//   const [loadingResume, setLoadingResume] = useState({});

//   useEffect(() => {
//     fetchInterviewSchedule();
//   }, []);

//   const fetchInterviewSchedule = () => {
//     const userEmail = localStorage.getItem("email");
//     if (!userEmail) {
//       console.error("User email not found in local storage");
//       return;
//     }
//     setLoadingTable(true); // <-- Enable loader before data fetch

//     fetch(`https://tmaasbackend.tdtlworld.com/interviewers/`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({ email: userEmail }),
//     })
//       .then((response) => {
//         if (!response.ok) {
//           throw new Error("Network response was not ok");
//         }
//         return response.json();
//       })
//       .then((data) => {
//         const formattedData = data.map((interview) => ({
//           ...interview,
//           date: formatDate(interview.date),
//           time: formatTime(interview.time),
//         }));
//         setInterviewSchedule(formattedData);

//         // Fetch applied jobs for the interview schedule
//         fetchAppliedJobsForSchedule(formattedData);
//       })
//       .catch((error) => {
//         console.error("Error fetching interview schedule:", error);
//       })
//       .finally(() => setLoadingTable(false)); // <-- Disable loader after data fetch
//   };

//   const fetchAppliedJobsForSchedule = (schedule) => {
//     const candidateEmails = schedule.map(
//       (interview) => interview.candidate_email
//     );
//     candidateEmails.forEach((email) => {
//       fetch(`https://tmaasbackend.tdtlworld.com/get_applied_job/${email}/`)
//         .then((response) => {
//           if (!response.ok) {
//             throw new Error("Network response was not ok");
//           }
//           return response.json();
//         })
//         .then((appliedJobsResponse) => {
//           setAppliedJobs((prevJobs) => ({
//             ...prevJobs,
//             [email]: appliedJobsResponse.job_titles || ["Not Applied"],
//           }));
//         })
//         .catch((error) => {
//           console.error("Error fetching applied jobs:", error);
//         });
//     });
//   };

//   const formatDate = (dateString) => {
//     const date = new Date(dateString);
//     const formattedDate = `${date.getDate()}-${date.getMonth() + 1
//       }-${date.getFullYear()}`;
//     return formattedDate;
//   };

//   const formatTime = (timeString) => {
//     const time = new Date(`01/01/2022 ${timeString}`);
//     const formattedTime = time.toLocaleTimeString("en-US", {
//       hour: "numeric",
//       minute: "2-digit",
//     });
//     return formattedTime;
//   };

//   const handleViewClick = (candidateEmail) => {
//     setLoadingView((prev) => ({ ...prev, [candidateEmail]: true })); // Start loader for specific button
//     fetch("https://tmaasbackend.tdtlworld.com/get_applicant_data/", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({ email: candidateEmail }),
//     })
//       .then((response) => {
//         if (!response.ok) {
//           throw new Error("Network response was not ok");
//         }
//         return response.json();
//       })
//       .then((data) => {
//         setSelectedApplicant(data);
//         setShowModal(true);
//       })
//       .catch((error) => {
//         console.error("Error fetching applicant data:", error);
//       })
//       .finally(() => {
//         setLoadingView((prev) => ({ ...prev, [candidateEmail]: false })); // Stop loader for specific button
//       });

//   };

//   const handleViewResume = (candidateEmail) => {
//     setLoadingResume((prev) => ({ ...prev, [candidateEmail]: true })); // Start loader for specific button
//     fetch("https://tmaasbackend.tdtlworld.com/view_resume/", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({ email: candidateEmail }),
//     })
//       .then((response) => {
//         if (!response.ok) {
//           throw new Error("Network response was not ok");
//         }
//         return response.blob();
//       })
//       .then((blob) => {
//         const resumeBlobUrl = URL.createObjectURL(blob);
//         window.open(resumeBlobUrl, "_blank");
//       })
//       .catch((error) => {
//         console.error("Error fetching resume:", error);
//       })
//       .finally(() => {
//         setLoadingResume((prev) => ({ ...prev, [candidateEmail]: false })); // Stop loader for specific button
//       });
//   };

//   // Pagination logic
//   const indexOfLastItem = currentPage * itemsPerPage;
//   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//   const currentItems = interviewSchedule.slice(
//     indexOfFirstItem,
//     indexOfLastItem
//   );

//   const paginate = (pageNumber) => setCurrentPage(pageNumber);

//   const totalPages = Math.ceil(interviewSchedule.length / itemsPerPage);
//   const handleSearch = (e) => {
//     setSearchQuery(e.target.value);
//   };

//   const filteredSchedule = interviewSchedule.filter((interview) => {
//     const candidateMatch = interview.candidate
//       .toLowerCase()
//       .includes(searchQuery.toLowerCase());
//     const dateMatch = interview.date
//       .toLowerCase()
//       .includes(searchQuery.toLowerCase());
//     const appliedJobMatch = appliedJobs[interview.candidate_email]?.some(
//       (job) => job.toLowerCase().includes(searchQuery.toLowerCase())
//     );
//     return candidateMatch || dateMatch || appliedJobMatch;
//   });

//   return (
//     <>
//       {/* <div
//         style={{ display: "flex", flexDirection: "column", height: "100vh" }}
//       > */}
//       <DashNavbar />
//       <div className="wrapper">
//         <Sidebar />
//         <div className="col-lg-10 col-md-8 col-sm-12 main dash4">
//           <div className="container mt-4">
//             <div className="text-start">
//               <h3>Welcome to your Dashboard</h3>
//               <p>Below is your interview schedule:</p>
//             </div>
//             <div className="auth-inner mt-5 w-100">
//               <h2 className="mb-4">Interview Schedule</h2>
//               <div className="input-group justify-content-center align-items-center">
//                 <div className="form-outline w-50">
//                   <input
//                     type="search"
//                     className="form-control"
//                     placeholder="Search by Name or Email"
//                     onChange={handleSearch}
//                   />
//                   <button className="btn btn-primary searchicon text-xs">
//                     <i className="fas fa-search"></i>
//                   </button>
//                 </div>
//               </div>
//               <div className="table-responsive">
//                 <table className="table">
//                   <thead className="thead-dark">
//                     <tr>
//                       <th scope="col">Sr.No</th>
//                       <th scope="col">Candidate</th>
//                       <th scope="col">Date</th>
//                       <th scope="col">Time</th>
//                       <th scope="col">Applied Job</th>
//                       <th scope="col">Meeting Link</th>
//                       <th scope="col">Actions</th>
//                     </tr>
//                   </thead>
//                   {loadingTable ? ( // <-- Conditional rendering for loader
//                     <tbody>
//                       <tr>
//                         <td colSpan="7" className="text-center">
//                           <Spinner animation="border" /> {/* <-- Spinner */}
//                         </td>
//                       </tr>
//                     </tbody>
//                   ) : (
//                     <tbody>
//                       {filteredSchedule
//                         .slice(indexOfFirstItem, indexOfLastItem)
//                         .map((interview, index) => (
//                           <tr key={index}>
//                             <td>{indexOfFirstItem + index + 1}</td>
//                             <td>{interview.candidate}</td>
//                             <td>{interview.date}</td>
//                             <td>{interview.time}</td>
//                             <td className="w-25">
//                               {appliedJobs[interview.candidate_email]?.join(", ")}
//                             </td>
//                             <td>
//                               <Link
//                                 to={interview.meetLink}
//                                 target="_blank"
//                                 rel="noopener noreferrer"
//                                 className="btn btn-primary"
//                               >
//                                 Join Meeting
//                               </Link>
//                             </td>
//                             <td>
//                               <button
//                                 className="btn btn-secondary me-2 mb-8 mb-sm-0"
//                                 onClick={() =>
//                                   handleViewClick(interview.candidate_email)
//                                 }

//                                 disabled={loadingView[interview.candidate_email]} // Disable button based on specific email

//                               >

//                                 {loadingView[interview.candidate_email] ? ( // Show spinner only for the loading button
//                                   <span
//                                     className="spinner-border spinner-border-sm"
//                                     role="status"
//                                     aria-hidden="true"
//                                   ></span>
//                                 ) : (
//                                   "View"
//                                 )}

//                               </button>
//                               <button
//                                 className="btn btn-secondary mb-8 mb-sm-0"
//                                 onClick={() =>
//                                   handleViewResume(interview.candidate_email)
//                                 }
//                                 disabled={loadingResume[interview.candidate_email]} // Disable button based on specific email
//                               >
//                                 {loadingResume[interview.candidate_email] ? ( // Show spinner only for the loading button
//                                   <span
//                                     className="spinner-border spinner-border-sm"
//                                     role="status"
//                                     aria-hidden="true"
//                                   ></span>
//                                 ) : (
//                                   "Resume"
//                                 )}

//                               </button>
//                             </td>
//                           </tr>
//                         ))}
//                     </tbody>
//                   )}
//                 </table>
//               </div>
//               <Pagination className="mt-4">
//                 {/* <Pagination.First onClick={() => paginate(1)} disabled={currentPage === 1} /> */}
//                 <Pagination.Prev
//                   onClick={() => paginate(currentPage - 1)}
//                   disabled={currentPage === 1}
//                 />
//                 {Array.from({ length: totalPages }, (_, i) => (
//                   <Pagination.Item
//                     key={i + 1}
//                     active={i + 1 === currentPage}
//                     onClick={() => paginate(i + 1)}
//                   >
//                     {i + 1}
//                   </Pagination.Item>
//                 ))}
//                 <Pagination.Next
//                   onClick={() => paginate(currentPage + 1)}
//                   disabled={currentPage === totalPages}
//                 />
//                 {/* <Pagination.Last onClick={() => paginate(totalPages)} disabled={currentPage === totalPages} /> */}
//               </Pagination>
//             </div>
//           </div>
//         </div>
//       </div>
//       {/* </div> */}

//       <ApplicantDetailsModal
//         applicant={selectedApplicant || {}}
//         show={showModal}
//         onHide={() => setShowModal(false)}
//       />
//     </>
//   );
// }

// export default InterviewerDashboard;

//old code

// import React, { useState, useEffect } from "react";
// import DashNavbar from "./DashNavbar";
// import Sidebar from "./Sidebar";
// import ApplicantDetailsModal from "../ProfileForm/ApplicantDetailsModal";
// import "../css/dash.css";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "../css/PDFViewer.css";
// import { Link } from "react-router-dom";
// import { Pagination } from "react-bootstrap";
// import { Spinner } from "react-bootstrap"; // <-- Added Spinner from react-bootstrap

// function InterviewerDashboard() {
//   const [interviewSchedule, setInterviewSchedule] = useState([]);
//   const [showModal, setShowModal] = useState(false);
//   const [selectedApplicant, setSelectedApplicant] = useState(null);
//   const [appliedJobs, setAppliedJobs] = useState({});
//   const [currentPage, setCurrentPage] = useState(1);
//   const itemsPerPage = 5;
//   const [searchQuery, setSearchQuery] = useState("");
//   const [loadingTable, setLoadingTable] = useState(true); // <-- New state for table loader
//   const [loadingView, setLoadingView] = useState(""); // <-- New state for View button loader
//   const [loadingResume, setLoadingResume] = useState({});

//   useEffect(() => {
//     fetchInterviewSchedule();
//   }, []);

//   const fetchInterviewSchedule = () => {
//     const userEmail = localStorage.getItem("email");
//     if (!userEmail) {
//       console.error("User email not found in local storage");
//       return;
//     }
//     setLoadingTable(true); // <-- Enable loader before data fetch

//     fetch(`https://tmaasbackend.tdtlworld.com/interviewers/`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({ email: userEmail }),
//     })
//       .then((response) => {
//         if (!response.ok) {
//           throw new Error("Network response was not ok");
//         }
//         return response.json();
//       })
//       .then((data) => {
//         const formattedData = data.map((interview) => ({
//           ...interview,
//           date: formatDate(interview.date),
//           time: formatTime(interview.time),
//         }));
//         setInterviewSchedule(formattedData);

//         // Check if no interviews are scheduled and set a flag
//         if (formattedData.length === 0) {
//           setInterviewSchedule(null); // <-- Set interview schedule as null if empty
//         } else {
//           // Fetch applied jobs for the interview schedule
//           fetchAppliedJobsForSchedule(formattedData);
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching interview schedule:", error);
//       })
//       .finally(() => setLoadingTable(false)); // <-- Disable loader after data fetch
//   };

//   const fetchAppliedJobsForSchedule = (schedule) => {
//     const candidateEmails = schedule.map(
//       (interview) => interview.candidate_email
//     );
//     candidateEmails.forEach((email) => {
//       fetch(`https://tmaasbackend.tdtlworld.com/get_applied_job/${email}/`)
//         .then((response) => {
//           if (!response.ok) {
//             throw new Error("Network response was not ok");
//           }
//           return response.json();
//         })
//         .then((appliedJobsResponse) => {
//           setAppliedJobs((prevJobs) => ({
//             ...prevJobs,
//             [email]: appliedJobsResponse.job_titles || ["Not Applied"],
//           }));
//         })
//         .catch((error) => {
//           console.error("Error fetching applied jobs:", error);
//         });
//     });
//   };

//   const formatDate = (dateString) => {
//     const date = new Date(dateString);
//     const formattedDate = `${date.getDate()}-${date.getMonth() + 1
//       }-${date.getFullYear()}`;
//     return formattedDate;
//   };

//   const formatTime = (timeString) => {
//     const time = new Date(`01/01/2022 ${timeString}`);
//     const formattedTime = time.toLocaleTimeString("en-US", {
//       hour: "numeric",
//       minute: "2-digit",
//     });
//     return formattedTime;
//   };

//   const handleViewClick = (candidateEmail) => {
//     setLoadingView((prev) => ({ ...prev, [candidateEmail]: true })); // Start loader for specific button
//     fetch("https://tmaasbackend.tdtlworld.com/get_applicant_data/", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({ email: candidateEmail }),
//     })
//       .then((response) => {
//         if (!response.ok) {
//           throw new Error("Network response was not ok");
//         }
//         return response.json();
//       })
//       .then((data) => {
//         setSelectedApplicant(data);
//         setShowModal(true);
//       })
//       .catch((error) => {
//         console.error("Error fetching applicant data:", error);
//       })
//       .finally(() => {
//         setLoadingView((prev) => ({ ...prev, [candidateEmail]: false })); // Stop loader for specific button
//       });

//   };

//   const handleViewResume = (candidateEmail) => {
//     setLoadingResume((prev) => ({ ...prev, [candidateEmail]: true })); // Start loader for specific button
//     fetch("https://tmaasbackend.tdtlworld.com/view_resume/", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({ email: candidateEmail }),
//     })
//       .then((response) => {
//         if (!response.ok) {
//           throw new Error("Network response was not ok");
//         }
//         return response.blob();
//       })
//       .then((blob) => {
//         const resumeBlobUrl = URL.createObjectURL(blob);
//         window.open(resumeBlobUrl, "_blank");
//       })
//       .catch((error) => {
//         console.error("Error fetching resume:", error);
//       })
//       .finally(() => {
//         setLoadingResume((prev) => ({ ...prev, [candidateEmail]: false })); // Stop loader for specific button
//       });
//   };

//   // Pagination logic
//   const indexOfLastItem = currentPage * itemsPerPage;
//   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//   const currentItems = interviewSchedule ? interviewSchedule.slice(
//     indexOfFirstItem,
//     indexOfLastItem
//   ) : [];

//   const paginate = (pageNumber) => setCurrentPage(pageNumber);

//   const totalPages = interviewSchedule ? Math.ceil(interviewSchedule.length / itemsPerPage) : 1;

//   const handleSearch = (e) => {
//     setSearchQuery(e.target.value);
//   };

//   const filteredSchedule = interviewSchedule ? interviewSchedule.filter((interview) => {
//     const candidateMatch = interview.candidate
//       .toLowerCase()
//       .includes(searchQuery.toLowerCase());
//     const dateMatch = interview.date
//       .toLowerCase()
//       .includes(searchQuery.toLowerCase());
//     const appliedJobMatch = appliedJobs[interview.candidate_email]?.some(
//       (job) => job.toLowerCase().includes(searchQuery.toLowerCase())
//     );
//     return candidateMatch || dateMatch || appliedJobMatch;
//   }) : [];

//   return (
//     <>
//       <DashNavbar />
//       <div className="wrapper">
//         <Sidebar />
//         <div className="col-lg-10 col-md-8 col-sm-12 main dash4">
//           <div className="container mt-4">
//             <div className="text-start">
//               <h3>Welcome to your Dashboard</h3>
//               <p>Below is your interview schedule:</p>
//             </div>
//             <div className="auth-inner mt-5 w-100">
//               <h2 className="mb-4">Interview Schedule</h2>
//               <div className="input-group justify-content-center align-items-center">
//                 <div className="form-outline w-50">
//                   <input
//                     type="search"
//                     className="form-control"
//                     placeholder="Search by Name or Email"
//                     onChange={handleSearch}
//                   />
//                   <button className="btn btn-primary searchicon text-xs">
//                     <i className="fas fa-search"></i>
//                   </button>
//                 </div>
//               </div>
//               <div className="table-responsive">
//                 <table className="table">
//                   <thead className="thead-dark">
//                     <tr>
//                       <th scope="col">Sr.No</th>
//                       <th scope="col">Candidate</th>
//                       <th scope="col">Date</th>
//                       <th scope="col">Time</th>
//                       <th scope="col">Applied Job</th>
//                       <th scope="col">Meeting Link</th>
//                       <th scope="col">Actions</th>
//                     </tr>
//                   </thead>
//                   {loadingTable ? (
//                     <tbody>
//                       <tr>
//                         <td colSpan="7" className="text-center">
//                           <Spinner animation="border" />
//                         </td>
//                       </tr>
//                     </tbody>
//                   ) : interviewSchedule === null ? (
//                     <tbody>
//                       <tr>
//                         <td colSpan="7" className="text-center">
//                           Your interview has not been scheduled with any candidate.
//                         </td>
//                       </tr>
//                     </tbody>
//                   ) : (
//                     <tbody>
//                       {filteredSchedule
//                         .slice(indexOfFirstItem, indexOfLastItem)
//                         .map((interview, index) => (
//                           <tr key={index}>
//                             <td>{indexOfFirstItem + index + 1}</td>
//                             <td>{interview.candidate}</td>
//                             <td>{interview.date}</td>
//                             <td>{interview.time}</td>
//                             <td className="w-25">
//                               {appliedJobs[interview.candidate_email]?.join(", ")}
//                             </td>
//                             <td>
//                               <Link
//                                 to={interview.meetLink}
//                                 target="_blank"
//                                 rel="noopener noreferrer"
//                                 className="btn btn-primary"
//                               >
//                                 Join Meeting
//                               </Link>
//                             </td>
//                             <td>
//                               <button
//                                 className="btn btn-secondary me-2 mb-8 mb-sm-0"
//                                 onClick={() =>
//                                   handleViewClick(interview.candidate_email)
//                                 }
//                                 disabled={loadingView[interview.candidate_email]}
//                               >
//                                 {loadingView[interview.candidate_email] ? (
//                                   <span
//                                     className="spinner-border spinner-border-sm"
//                                     role="status"
//                                     aria-hidden="true"
//                                   ></span>
//                                 ) : (
//                                   "View"
//                                 )}
//                               </button>
//                               <button
//                                 className="btn btn-secondary mb-8 mb-sm-0"
//                                 onClick={() =>
//                                   handleViewResume(interview.candidate_email)
//                                 }
//                                 disabled={loadingResume[interview.candidate_email]}
//                               >
//                                 {loadingResume[interview.candidate_email] ? (
//                                   <span
//                                     className="spinner-border spinner-border-sm"
//                                     role="status"
//                                     aria-hidden="true"
//                                   ></span>
//                                 ) : (
//                                   "Resume"
//                                 )}
//                               </button>
//                             </td>
//                           </tr>
//                         ))}
//                     </tbody>
//                   )}
//                 </table>
//               </div>
//               <Pagination className="mt-4">
//                 <Pagination.Prev
//                   onClick={() => paginate(currentPage - 1)}
//                   disabled={currentPage === 1}
//                 />
//                 {Array.from({ length: totalPages }, (_, i) => (
//                   <Pagination.Item
//                     key={i + 1}
//                     active={i + 1 === currentPage}
//                     onClick={() => paginate(i + 1)}
//                   >
//                     {i + 1}
//                   </Pagination.Item>
//                 ))}
//                 <Pagination.Next
//                   onClick={() => paginate(currentPage + 1)}
//                   disabled={currentPage === totalPages}
//                 />
//               </Pagination>
//             </div>
//           </div>
//         </div>
//       </div>

//       <ApplicantDetailsModal
//         applicant={selectedApplicant || {}}
//         show={showModal}
//         onHide={() => setShowModal(false)}
//       />
//     </>
//   );
// }

// export default InterviewerDashboard;
